// @mui
// -- system
import { Theme } from '@mui/system/createTheme';
import { Spacing } from '@mui/system/createTheme/createSpacing';

// ----------------------------------------------------------------------

interface IconButtonProps extends Theme {
    spacing: Spacing;
}

// ----------------------------------------------------------------------

const IconButton = (theme: IconButtonProps) => {
    return {
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1)
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default IconButton;
