// react
import { createContext, ReactNode } from 'react';

// prop types
import PropTypes from 'prop-types';

// hooks
import useLocalStorage from '../../hooks/useLocalStorage';

// ----------------------------------------------------------------------

const initialState = {
    themeMode: 'light',
    onChangeMode: (mode: string) => {},
    onToggleMode: () => {}
};

// ----------------------------------------------------------------------

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

interface SettingsProviderProps {
    children: ReactNode;
}

// ----------------------------------------------------------------------

const SettingsProvider = ({ children }: SettingsProviderProps) => {
    // hooks
    // -- local storage
    const [settings, setSettings] = useLocalStorage('settings', {
        ...initialState
    });

    // theme mode callback(s)
    // -- toggle mode
    const onToggleMode = () => {
        setSettings({
            ...settings,
            themeMode: settings.themeMode === 'light' ? 'dark' : 'light'
        });
    };
    // -- change mode
    const onChangeMode = (mode: string) => {
        setSettings({
            ...settings,
            themeMode: mode
        });
    };

    return (
        <SettingsContext.Provider
            value={{
                ...settings,
                // -- mode
                onToggleMode,
                onChangeMode
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};

SettingsProvider.propTypes = {
    children: PropTypes.node.isRequired
};

// ----------------------------------------------------------------------

export { SettingsProvider, SettingsContext };
