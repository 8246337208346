// react
import { ReactNode } from 'react';

// prop types
import PropTypes from 'prop-types';

// @mui
// -- styles
import { styled } from '@mui/material/styles';
// -- components
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------

interface IPaper {
    selected: boolean;
}

interface IActionOption {
    children: ReactNode;
    selected: boolean;
}

// ----------------------------------------------------------------------

const ActionOptionStyle = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'selected'
})<IPaper>(({ selected, theme }) => ({
    display: 'flex',
    maxHeight: '79px',
    background: '#F5F5F5',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: '6.56436px',
    ...(selected && {
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: theme.shadows[5]
    })
}));

// ----------------------------------------------------------------------

const ActionOption = ({ selected, children }: IActionOption) => {
    return <ActionOptionStyle selected={selected}>{children}</ActionOptionStyle>;
};

ActionOption.propTypes = {
    selected: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired
};

// ----------------------------------------------------------------------

export default ActionOption;
