const Divider = () => {
    return {
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)'
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default Divider;
