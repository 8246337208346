// react
import { useState } from 'react';

// router
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- lab
import { LoadingButton } from '@mui/lab';
// -- components
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import MuiLink from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
// -- icons
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';

// forms
import { useForm, SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

// routes
// -- constants
import routes from '../../../constants/routes';

// hooks
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';

// redux
// -- actions
import { requestPasswordRecovery, IForgotPasswordFormFields } from '../../../store/session/sessionSlice';

// layouts
import ScaffoldLayout from '../../../layouts/scaffold';

// ----------------------------------------------------------------------

interface IForgotPasswordFormState {
    error: boolean;
    isRequested: boolean;
    isSubmitting: boolean;
}

// ----------------------------------------------------------------------

const schema = Joi.object({
    email: Joi.string().required()
});

// ----------------------------------------------------------------------

const ForgotPasswordPage = () => {
    // hooks
    // -- theme
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- router
    const navigate = useNavigate();
    // -- redux
    const dispatch = useAppDispatch();
    const session = useAppSelector((state) => state.session);
    // -- forms
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<IForgotPasswordFormFields>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: joiResolver(schema)
    });

    // state
    // -- forgot form
    const [forgotPasswordForm, setForgotPasswordForm] = useState<IForgotPasswordFormState>({
        error: false,
        isRequested: false,
        isSubmitting: false
    });

    // form callback(s)
    const handleRequest: SubmitHandler<IForgotPasswordFormFields> = async (data: IForgotPasswordFormFields) => {
        setForgotPasswordForm({
            ...forgotPasswordForm,
            error: false,
            isRequested: false,
            isSubmitting: true
        });

        dispatch(requestPasswordRecovery(data))
            .unwrap()
            .then((res) => {
                setForgotPasswordForm({
                    ...forgotPasswordForm,
                    error: false,
                    isRequested: true,
                    isSubmitting: false
                });
            })
            .catch((err) => {
                setForgotPasswordForm({
                    ...forgotPasswordForm,
                    error: true,
                    isRequested: false,
                    isSubmitting: false
                });
            });
    };

    if (session.checked && session.verified) {
        navigate(routes.dashboard);
    }

    if (session.checked && !session.verified) {
        return (
            <ScaffoldLayout showSideImages pageTitle="Password recovery">
                <Grid container mt={7}>
                    <Grid item xs={12} px={isSmDown ? 2 : 10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {forgotPasswordForm.isRequested ? (
                            <Stack
                                p={5}
                                spacing={3}
                                sx={{
                                    maxWidth: '640px',
                                    textAlign: 'center'
                                }}
                            >
                                <CheckOutlined style={{ color: '#208c46', fontSize: '60px' }} />

                                <Typography>
                                    {
                                        'We have emailed you instructions on how to reset your password. If you are having trouble accessing your email, please contact our support team.'
                                    }
                                </Typography>
                            </Stack>
                        ) : (
                            <Box
                                p={5}
                                sx={{
                                    maxWidth: '640px',
                                    textAlign: 'center',
                                    borderRadius: '27.6738px',
                                    border: '1.10695px solid #DFDFDF',
                                    opacity: 0.9,
                                    background: 'linear-gradient(128.68deg, #2F4A7F 24.59%, #00B9F2 137.76%)'
                                }}
                            >
                                <Box component={Typography} color="common.white" variant="h6">
                                    {'Reset your password?'}
                                </Box>

                                {!forgotPasswordForm.isSubmitting && forgotPasswordForm.error && (
                                    <Box mt={2} component={Alert} variant="filled" severity="error">
                                        {'There was an error communicating with the remote server, please try again later.'}
                                    </Box>
                                )}

                                <form onSubmit={handleSubmit(handleRequest)}>
                                    <Stack mt={3} spacing={1} direction="row" alignItems="center">
                                        <OutlinedInput
                                            id="email"
                                            type="text"
                                            size="small"
                                            autoComplete="off"
                                            disabled={isSubmitting || forgotPasswordForm.isSubmitting}
                                            placeholder="Enter email address"
                                            fullWidth
                                            sx={{ bgcolor: 'common.white' }}
                                            {...register('email')}
                                        />

                                        {errors.email && (
                                            <FormHelperText error id="forgot-password-form-helper-text-email">
                                                {errors.email?.message}
                                            </FormHelperText>
                                        )}
                                    </Stack>

                                    <Box mt={3}>
                                        <LoadingButton
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting || forgotPasswordForm.isSubmitting}
                                            loading={isSubmitting || forgotPasswordForm.isSubmitting}
                                            sx={{
                                                px: isSmDown ? 10 : 20,
                                                ':hover': {
                                                    bgcolor: theme.palette.primary.main
                                                }
                                            }}
                                        >
                                            {'Request reset link'}
                                        </LoadingButton>
                                    </Box>
                                </form>

                                <Box mt={4}>
                                    <MuiLink
                                        color="common.white"
                                        component={ReactRouterLink}
                                        to={routes.authentication.login}
                                        sx={{ fontSize: '15px' }}
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <ArrowLeftOutlined />

                                            <Typography variant="subtitle2">{'Back to login'}</Typography>
                                        </Stack>
                                    </MuiLink>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </ScaffoldLayout>
        );
    }

    return null;
};

// ----------------------------------------------------------------------

export default ForgotPasswordPage;
