// react
import { useState, useEffect, ReactNode, MouseEvent } from 'react';

// router
import { Link as ReactRouterLink } from 'react-router-dom';

// prop types
import PropTypes from 'prop-types';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MuiLink from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
// -- icons
import { MenuOutlined, CloseOutlined, PoweroffOutlined } from '@ant-design/icons';

// Burger menu
import { slide as BurgerMenu } from 'react-burger-menu';

// hooks
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';

// routes
// -- constants
import routes from '../../constants/routes';

// redux actions
import { logout } from '../../store/session/sessionSlice';

// components
// -- shared
import Image from '../../components/image';

// images
// -- local
import LogoImage from '../../assets/images/logo.svg';
import SailingShipImage from '../../assets/images/EY3teX.svg';
import LocationImage from '../../assets/images/H4AaTo.svg';
import CloudOnTopOfPlaneImage from '../../assets/images/RHm9nK.svg';
import PlaneImage from '../../assets/images/93Kkf7.svg';
import CloudOnBottomOfPlaneImage from '../../assets/images/Ck3Emz.svg';

// styles
import './header-styles.css';

// ----------------------------------------------------------------------

interface ScaffoldProps {
    pageTitle?: string;
    backLinkHref?: string;
    horizontalLogo?: boolean;
    showSideImages?: boolean;
    children: ReactNode;
}

// ----------------------------------------------------------------------

const Scaffold = ({ pageTitle = '', horizontalLogo = false, showSideImages = false, backLinkHref, children }: ScaffoldProps) => {
    // hooks
    // -- theme
    const theme = useTheme();
    // breakpoints
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- redux
    const dispatch = useAppDispatch();
    const session = useAppSelector((state) => state.session);

    // state
    // -- mobile nav status
    const [mobileNavbarStatus, setMobileNavbarStatus] = useState<null | HTMLElement>(null);

    // mobile nav callback(s)
    // -- open
    const handleOpenMobileNavMenu = (event: MouseEvent<HTMLElement>) => {
        setMobileNavbarStatus(event.currentTarget);
    };
    // -- close
    const handleCloseMobileNavMenu = () => {
        setMobileNavbarStatus(null);
    };

    // handle logout
    const handleLogout = async (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();

        await dispatch(logout());
    };

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    return (
        <>
            <CssBaseline />

            {!isSmDown && showSideImages && (
                <>
                    <Stack mt={8} spacing={5} alignItems="flex-start" sx={{ position: 'absolute', top: '450px', left: 0, zIndex: -1 }}>
                        <Box sx={{ position: 'relative', top: '75px', left: '210px' }}>
                            <Image
                                alt=""
                                src={LocationImage}
                                sx={{
                                    width: '45px',
                                    height: '65px'
                                }}
                            />
                        </Box>

                        <Image
                            alt=""
                            src={SailingShipImage}
                            sx={{
                                width: '400px',
                                height: '200px'
                            }}
                        />
                    </Stack>

                    <Stack spacing={5} alignItems="flex-end" sx={{ position: 'absolute', top: '410px', right: 0, zIndex: -1 }}>
                        <Image
                            alt=""
                            src={CloudOnTopOfPlaneImage}
                            sx={{
                                width: '250px',
                                height: '65px'
                            }}
                        />

                        <Image
                            alt=""
                            src={PlaneImage}
                            sx={{
                                width: '150px',
                                height: '100px'
                            }}
                        />

                        <Image
                            alt=""
                            src={CloudOnBottomOfPlaneImage}
                            sx={{
                                width: '130px',
                                height: '40px'
                            }}
                        />
                    </Stack>
                </>
            )}

            <Grid container>
                <Grid
                    item
                    xs={3}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {session.checked && session.verified && (
                        <>
                            {!isSmDown && (
                                <Button
                                    onClick={handleOpenMobileNavMenu}
                                    variant="contained"
                                    size="small"
                                    sx={{ width: 'max-content', fontWeight: 600 }}
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <MenuOutlined style={{ color: '#fff', fontSize: '12px' }} />

                                        <Typography variant="body2">{'Menu'}</Typography>
                                    </Stack>
                                </Button>
                            )}

                            <BurgerMenu
                                width="100%"
                                customBurgerIcon={false}
                                customCrossIcon={false}
                                isOpen={Boolean(mobileNavbarStatus)}
                                onClose={handleCloseMobileNavMenu}
                            >
                                <Box p={2}>
                                    <Grid container>
                                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton
                                                onClick={handleCloseMobileNavMenu}
                                                sx={{
                                                    color: theme.palette.primary.main
                                                }}
                                            >
                                                <CloseOutlined />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={4} alignItems="center" justifyContent="center" sx={{ display: 'flex' }}>
                                            <MuiLink to={routes.dashboard} component={ReactRouterLink}>
                                                <Image
                                                    alt="medship-feeders-dmcc-logo"
                                                    sx={{
                                                        width: 65,
                                                        height: 55
                                                    }}
                                                    src={LogoImage}
                                                />
                                            </MuiLink>
                                        </Grid>
                                        <Grid item xs={4} />
                                    </Grid>
                                </Box>

                                <List>
                                    <ListItemButton
                                        component={ReactRouterLink}
                                        to={routes.dashboard}
                                        sx={{
                                            py: 2,
                                            borderTop: '2px solid rgba(17, 27, 33, .2)',
                                            borderBottom: '1px solid rgba(17, 27, 33, .2)'
                                        }}
                                    >
                                        <ListItemText sx={{ px: 1 }}>
                                            <Typography variant="h4">{'Home'}</Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                    <ListItemButton
                                        component={ReactRouterLink}
                                        to={routes.paymentVouchers.actions}
                                        sx={{
                                            py: 2,
                                            borderTop: '1px solid rgba(17, 27, 33, .2)',
                                            borderBottom: '1px solid rgba(17, 27, 33, .2)'
                                        }}
                                    >
                                        <ListItemText sx={{ px: 1 }}>
                                            <Typography variant="h4">{'Payment vouchers'}</Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                    <ListItemButton
                                        component={ReactRouterLink}
                                        to={routes.receiptVouchers.actions}
                                        sx={{
                                            py: 2,
                                            borderTop: '1px solid rgba(17, 27, 33, .2)',
                                            borderBottom: '1px solid rgba(17, 27, 33, .2)'
                                        }}
                                    >
                                        <ListItemText sx={{ px: 1 }}>
                                            <Typography variant="h4">{'Receipt vouchers'}</Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                    <ListItemButton
                                        onClick={handleLogout}
                                        sx={{
                                            py: 2
                                        }}
                                    >
                                        <ListItemText sx={{ px: 1 }}>
                                            <Typography variant="h4">{'Logout'}</Typography>
                                        </ListItemText>
                                    </ListItemButton>
                                </List>

                                <Box
                                    sx={{
                                        color: 'common.white',
                                        backgroundColor: theme.palette.primary.main,
                                        pt: 2,
                                        height: '100%'
                                    }}
                                >
                                    <Grid container>
                                        <Grid item sm={12} sx={{ width: '100%' }}>
                                            <Grid container px={2}>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={4}
                                                    sx={{
                                                        alignSelf: 'center'
                                                    }}
                                                >
                                                    <Typography variant="subtitle1">{'2023 © Medship Feeders DMCC'}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </BurgerMenu>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Stack direction={horizontalLogo ? 'row' : 'column'} mt={5} spacing={1.5} alignItems="center" justifyContent="center">
                        <Image
                            alt="medship feeders dmcc logo"
                            src={LogoImage}
                            sx={{
                                width: '145px',
                                height: '120px'
                            }}
                        />

                        <Typography
                            variant="h6"
                            color="primary"
                            sx={{ lineHeight: 1, fontSize: horizontalLogo ? '23px' : '20px', fontWeight: 800 }}
                        >
                            {'MEDSHIP FEEDERS DMCC'}
                        </Typography>

                        {session.checked && session.verified && isSmDown && (
                            <Box pt={2}>
                                <Button
                                    onClick={handleOpenMobileNavMenu}
                                    variant="contained"
                                    size="small"
                                    sx={{ width: 'max-content', fontWeight: 600 }}
                                >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <MenuOutlined style={{ color: '#fff', fontSize: '12px' }} />

                                        <Typography variant="body2">{'Menu'}</Typography>
                                    </Stack>
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={3}
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex',
                            lg: 'flex'
                        },
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {session.checked && session.verified && (
                        <Stack alignItems="flex-end">
                            <Stack direction="row" alignItems="center">
                                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                                    {`Welcome ${session.data.name}`}
                                </Typography>

                                <IconButton onClick={handleLogout}>
                                    <PoweroffOutlined style={{ fontSize: '15px', color: theme.palette.primary.main }} />
                                </IconButton>
                            </Stack>

                            <Button
                                variant="contained"
                                size="small"
                                component={ReactRouterLink}
                                to={routes.dashboard}
                                sx={{ width: 'max-content', fontWeight: 600 }}
                            >
                                {'Home'}
                            </Button>
                        </Stack>
                    )}
                </Grid>
            </Grid>

            {children}

            <Box component={Divider} sx={{ mt: 20 }} />

            <Box px={2} my={5} sx={{ textAlign: 'center' }}>
                <Typography variant="body2" sx={{ fontWeight: 800 }}>
                    {'Office No. 1010, 1009, Clover Bay Tower , Busniess Bay, Burj Khalifa'} {!isSmDown && <br />}
                    {'Zone, Dubai - United Arab Emirates Tel: +97142941007'}
                </Typography>
            </Box>
        </>
    );
};

Scaffold.propTypes = {
    pageTitle: PropTypes.string,
    backLinkHref: PropTypes.string,
    horizontalLogo: PropTypes.bool,
    showSideImages: PropTypes.bool,
    children: PropTypes.node.isRequired
};

// ----------------------------------------------------------------------

export default Scaffold;
