const typography = {
    fontFamily: '"Cairo", "Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
        fontWeight: 400,
        fontSize: 26,
        letterSpacing: 0.5
    }
};

// ----------------------------------------------------------------------

export default typography;
