// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// ----------------------------------------------------------------------

const Greeting = () => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- breakpoints
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack mt={10} spacing={1.5} alignItems="center" sx={{ textAlign: 'center' }}>
            <Typography
                variant="h6"
                color="primary"
                sx={{ fontSize: isSmDown ? '26px' : isMdDown ? '30px' : '35px', lineHeight: 1, fontWeight: 700 }}
            >
                Hello Good Day
            </Typography>

            <Typography
                variant="h2"
                color="common.black"
                sx={{ fontSize: isSmDown ? '22px' : isMdDown ? '28px' : '35px', lineHeight: 1, fontWeight: 700 }}
            >
                What would you like to create today
            </Typography>
        </Stack>
    );
};

// ----------------------------------------------------------------------

export default Greeting;
