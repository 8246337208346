// react
import { useState, useEffect } from 'react';

// router
import { useNavigate } from 'react-router-dom';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- lab
import { LoadingButton } from '@mui/lab';
// -- components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';

// forms
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

// number format
import { NumericFormat } from 'react-number-format';

// file input
import { MuiFileInput } from 'mui-file-input';

// notistack
import { useSnackbar } from 'notistack';

// hooks
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';

// redux
// -- actions
import { create, ICreatePaymentVoucherFormFields } from '../../../store/paymentVouchers/paymentVouchersSlice';

// routes
// -- constants
import routes from '../../../constants/routes';

// layouts
import ScaffoldLayout from '../../../layouts/scaffold';

// components
// -- shared
import Image from '../../../components/image';

// images
// -- local
import CreatePaymentFormImage from '../../../assets/images/nwAHC3.svg';

// ----------------------------------------------------------------------

interface ICreateFormState {
    error: boolean;
    isSubmitting: boolean;
}

// ----------------------------------------------------------------------

const schema = Joi.object({
    dhs: Joi.number().required().label('Dhs'),
    fils: Joi.number().required().label('Fils'),
    voucher_date: Joi.any().required().label('Voucher date'),
    paid_to: Joi.string().max(250).required().label('Paid to'),
    sum_of: Joi.string().max(250).required().label('Sum of'),
    on_settlement_of: Joi.string().max(250).required().label('On settlement of'),
    media: Joi.any().label('Media'),
    payment_type: Joi.string().required().label('Payment type'),
    bank: Joi.any().when('payment_type', { is: 'bank', then: Joi.string().max(250).required(), otherwise: Joi.optional() }),
    cheque_number: Joi.any().when('payment_type', {
        is: 'bank',
        then: Joi.string().max(250).required().label('Cheque number'),
        otherwise: Joi.optional()
    }),
    cheque_date: Joi.any().when('payment_type', { is: 'bank', then: Joi.any().required().label('Cheque date'), otherwise: Joi.optional() })
});

// ----------------------------------------------------------------------

const PaymentVouchersCreatePage = () => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- breakpoints
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- router
    const navigate = useNavigate();
    // -- redux
    const dispatch = useAppDispatch();
    const session = useAppSelector((state) => state.session);
    // -- notistack
    const { enqueueSnackbar } = useSnackbar();
    // -- forms
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        formState: { errors, isSubmitting }
    } = useForm<ICreatePaymentVoucherFormFields>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: joiResolver(schema),
        defaultValues: {
            dhs: 0,
            fils: 0,
            paid_to: '',
            sum_of: '',
            on_settlement_of: '',
            media: undefined,
            payment_type: 'cash',
            cheque_number: '',
            cheque_date: undefined,
            voucher_date: undefined
        }
    });
    // -- form watchers
    const paymentTypeWatcher = watch('payment_type');

    // state
    // -- create form
    const [resourceForm, setResourceForm] = useState<ICreateFormState>({
        error: false,
        isSubmitting: false
    });

    // form callback(s)
    const handleCreate: SubmitHandler<ICreatePaymentVoucherFormFields> = async (data: ICreatePaymentVoucherFormFields) => {
        setResourceForm({
            ...resourceForm,
            isSubmitting: true
        });

        dispatch(create(data))
            .unwrap()
            .then((res) => {
                enqueueSnackbar('The new payment voucher created successfully...', {
                    variant: 'success'
                });

                reset({
                    dhs: 0,
                    fils: 0,
                    paid_to: '',
                    sum_of: '',
                    on_settlement_of: '',
                    media: undefined,
                    payment_type: 'cash',
                    cheque_number: '',
                    cheque_date: undefined,
                    voucher_date: undefined
                });
            })
            .catch((err) => {
                if (err.status === 422) {
                    enqueueSnackbar('The given data was invalid, please review the form and try again', {
                        variant: 'warning'
                    });

                    for (let field of Object.keys(err.data.errors)) {
                        for (let message of err.data.errors[field]) {
                            setError(field as keyof ICreatePaymentVoucherFormFields, {
                                type: 'server',
                                message: message
                            });
                        }
                    }
                } else {
                    enqueueSnackbar('Oops!, something went wrong...', {
                        variant: 'error'
                    });
                }
            })
            .finally(() => {
                setResourceForm({
                    ...resourceForm,
                    isSubmitting: false
                });
            });
    };

    useEffect(() => {
        document.documentElement!.scrollTop = 0;
        document.scrollingElement!.scrollTop = 0;

        if (session.checked && !session.verified) {
            navigate(routes.authentication.login);
        }
    }, [session, navigate]);

    if (session.checked && session.verified) {
        return (
            <ScaffoldLayout horizontalLogo backLinkHref={routes.paymentVouchers.actions} pageTitle="Create - Payment vouchers">
                <Box component={Divider} sx={{ my: 4 }} />

                <Stack
                    px={isSmDown ? 2 : 0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={isSmDown ? 2 : 5}
                    sx={{ textAlign: 'center' }}
                >
                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: isSmDown ? '22px' : isMdDown ? '26px' : '33px',
                            fontWeight: 700,
                            color: theme.palette.primary.main
                        }}
                    >
                        {'PAYMENT VOUCHER'}
                    </Typography>

                    <Typography
                        variant="h2"
                        sx={{
                            fontSize: '33px',
                            fontWeight: 700,
                            color: theme.palette.primary.main
                        }}
                    >
                        {'سند صرف'}
                    </Typography>
                </Stack>

                <form onSubmit={handleSubmit(handleCreate)}>
                    <Grid mt={5} container px={isMdDown ? 2 : 5} columnSpacing={5}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'flex'
                                }
                            }}
                        >
                            <Image alt="" src={CreatePaymentFormImage} sx={{ width: '620px', height: '511px' }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Box py={4} px={3} sx={{ background: '#F9F9F9', border: '1px solid #DFDFDF', borderRadius: '24px' }}>
                                <Grid
                                    container
                                    spacing={{
                                        xs: 2,
                                        sm: 2,
                                        md: 4,
                                        lg: 4
                                    }}
                                >
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="dhs"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'Dhs'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        width: '100%',
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="dhs"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'Dhs'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="dhs" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'درهم'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={9}
                                                md={9}
                                                lg={9}
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="dhs"
                                                    render={({ field: { onChange, name, value } }) => (
                                                        <NumericFormat
                                                            size="small"
                                                            customInput={OutlinedInput}
                                                            decimalScale={0}
                                                            autoComplete="off"
                                                            disabled={isSubmitting || resourceForm.isSubmitting}
                                                            sx={{
                                                                borderRadius: '6px',
                                                                bgcolor: 'common.white',
                                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                            }}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange({
                                                                    ...e,
                                                                    target: {
                                                                        ...e.target,
                                                                        value: parseInt(e.target.value)
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            {errors.dhs && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-dhs"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.dhs?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="fils"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'Fils'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        width: '100%',
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="fils"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'Fils'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="fils" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'فلس'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={9}
                                                md={9}
                                                lg={9}
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Controller
                                                    control={control}
                                                    name="fils"
                                                    render={({ field: { onChange, name, value } }) => (
                                                        <NumericFormat
                                                            size="small"
                                                            customInput={OutlinedInput}
                                                            decimalScale={0}
                                                            autoComplete="off"
                                                            disabled={isSubmitting || resourceForm.isSubmitting}
                                                            sx={{
                                                                borderRadius: '6px',
                                                                bgcolor: 'common.white',
                                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                            }}
                                                            name={name}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange({
                                                                    ...e,
                                                                    target: {
                                                                        ...e.target,
                                                                        value: parseInt(e.target.value)
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            {errors.fils && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-fils"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.fils?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="voucher_date"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'Date'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        width: '100%',
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="voucher_date"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'Date'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="voucher_date" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'التاريخ'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={9}
                                                md={9}
                                                lg={9}
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Controller
                                                    name="voucher_date"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <MobileDatePicker
                                                            format="DD-MM-Y"
                                                            slotProps={{
                                                                textField: {
                                                                    disabled: isSubmitting || resourceForm.isSubmitting,
                                                                    autoComplete: 'off',
                                                                    size: 'small',
                                                                    error: Boolean(errors.voucher_date),
                                                                    fullWidth: true
                                                                }
                                                            }}
                                                            sx={{
                                                                borderRadius: '6px',
                                                                bgcolor: 'common.white',
                                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                            }}
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            {errors.voucher_date && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-voucher_date"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.voucher_date?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    pt={isSmDown ? 2 : 5}
                                    container
                                    spacing={{
                                        xs: 2,
                                        sm: 2,
                                        md: 4,
                                        lg: 4
                                    }}
                                >
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="paid_to"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'Paid to M/s.'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="paid_to"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'Paid to M/s.'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="paid_to" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'نصرف الى السادة'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <OutlinedInput
                                                    id="paid_to"
                                                    type="text"
                                                    size="small"
                                                    autoComplete="off"
                                                    disabled={isSubmitting || resourceForm.isSubmitting}
                                                    error={Boolean(errors.paid_to)}
                                                    fullWidth
                                                    sx={{
                                                        borderRadius: '6px',
                                                        bgcolor: 'common.white',
                                                        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                    }}
                                                    {...register('paid_to')}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    },
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <InputLabel htmlFor="paid_to" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                    {'نصرف الى السادة'}
                                                </InputLabel>
                                            </Grid>

                                            {errors.paid_to && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-paid-to"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.paid_to?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="sum_of"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'The Sum of'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="sum_of"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'The Sum of'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="sum_of" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'مبلغ وقدره (درهم)'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <OutlinedInput
                                                    id="sum_of"
                                                    type="text"
                                                    size="small"
                                                    autoComplete="off"
                                                    disabled={isSubmitting || resourceForm.isSubmitting}
                                                    error={Boolean(errors.sum_of)}
                                                    fullWidth
                                                    sx={{
                                                        borderRadius: '6px',
                                                        bgcolor: 'common.white',
                                                        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                    }}
                                                    {...register('sum_of')}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    },
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <InputLabel htmlFor="sum_of" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                    {'مبلغ وقدره (درهم)'}
                                                </InputLabel>
                                            </Grid>

                                            {errors.sum_of && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-sum-of"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.sum_of?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="on_settlement_of"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'On settlement of'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="on_settlement_of"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'On settlement of'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="sum_of" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'وذلك عن'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <OutlinedInput
                                                    id="on_settlement_of"
                                                    type="text"
                                                    size="small"
                                                    autoComplete="off"
                                                    disabled={isSubmitting || resourceForm.isSubmitting}
                                                    error={Boolean(errors.on_settlement_of)}
                                                    fullWidth
                                                    sx={{
                                                        borderRadius: '6px',
                                                        bgcolor: 'common.white',
                                                        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                    }}
                                                    {...register('on_settlement_of')}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    },
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <InputLabel htmlFor="on_settlement_of" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                    {'وذلك عن'}
                                                </InputLabel>
                                            </Grid>

                                            {errors.on_settlement_of && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-on_settlement_of"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.on_settlement_of?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="media"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'Add media'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="media"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'Add media'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="Add media" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'ارفق صورة'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    '& label': {
                                                        fontSize: '12px'
                                                    }
                                                }}
                                            >
                                                <Controller
                                                    name="media"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <MuiFileInput
                                                            size="small"
                                                            disabled={isSubmitting || resourceForm.isSubmitting}
                                                            sx={{
                                                                borderRadius: '6px',
                                                                bgcolor: 'common.white',
                                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                            }}
                                                            inputProps={{ accept: 'image/jpeg, image/jpg, image/png, image/bmp' }}
                                                            {...field}
                                                            id="media"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    },
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <InputLabel htmlFor="media" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                    {'اضف صورة'}
                                                </InputLabel>
                                            </Grid>

                                            {errors.media && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-media"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.media?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={3}
                                                md={3}
                                                lg={3}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start'
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="payment_type"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'flex',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                >
                                                    {'Payment type'}
                                                </InputLabel>

                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={0.5}
                                                    sx={{
                                                        display: {
                                                            xs: 'flex',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none'
                                                        }
                                                    }}
                                                >
                                                    <InputLabel
                                                        htmlFor="payment_type"
                                                        sx={{
                                                            fontWeight: 700,
                                                            color: 'common.black'
                                                        }}
                                                    >
                                                        {'Payment type'}
                                                    </InputLabel>

                                                    <InputLabel htmlFor="payment_type" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                        {'طريقة الدفع'}
                                                    </InputLabel>
                                                </Stack>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Controller
                                                    name="payment_type"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            size="small"
                                                            disabled={isSubmitting || resourceForm.isSubmitting}
                                                            fullWidth
                                                            sx={{
                                                                borderRadius: '6px',
                                                                bgcolor: 'common.white',
                                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                            }}
                                                            error={Boolean(errors.payment_type)}
                                                            {...field}
                                                        >
                                                            <MenuItem value="cash">{'Cash'}</MenuItem>
                                                            <MenuItem value="bank">{'Bank'}</MenuItem>
                                                        </Select>
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    },
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <InputLabel htmlFor="payment_type" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                    {'طريقة الدفع'}
                                                </InputLabel>
                                            </Grid>

                                            {errors.payment_type && (
                                                <Grid xs={12}>
                                                    <FormHelperText
                                                        error
                                                        id="create-payment-voucher-form-helper-text-payment-type"
                                                        sx={{ fontWeight: 700 }}
                                                    >
                                                        {errors.payment_type?.message}
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                    {paymentTypeWatcher === 'bank' && (
                                        <>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start'
                                                        }}
                                                    >
                                                        <InputLabel
                                                            htmlFor="bank"
                                                            sx={{
                                                                fontWeight: 700,
                                                                color: 'common.black',
                                                                display: {
                                                                    xs: 'none',
                                                                    sm: 'flex',
                                                                    md: 'flex',
                                                                    lg: 'flex'
                                                                }
                                                            }}
                                                        >
                                                            {'Bank'}
                                                        </InputLabel>

                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            pb={0.5}
                                                            sx={{
                                                                display: {
                                                                    xs: 'flex',
                                                                    sm: 'none',
                                                                    md: 'none',
                                                                    lg: 'none'
                                                                }
                                                            }}
                                                        >
                                                            <InputLabel
                                                                htmlFor="bank"
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    color: 'common.black'
                                                                }}
                                                            >
                                                                {'Bank'}
                                                            </InputLabel>

                                                            <InputLabel htmlFor="bank" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                                {'البنك'}
                                                            </InputLabel>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                    >
                                                        <OutlinedInput
                                                            id="bank"
                                                            type="text"
                                                            size="small"
                                                            autoComplete="off"
                                                            disabled={isSubmitting || resourceForm.isSubmitting}
                                                            error={Boolean(errors.bank)}
                                                            fullWidth
                                                            sx={{
                                                                borderRadius: '6px',
                                                                bgcolor: 'common.white',
                                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                            }}
                                                            {...register('bank')}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={{
                                                            display: {
                                                                xs: 'none',
                                                                sm: 'flex',
                                                                md: 'flex',
                                                                lg: 'flex'
                                                            },
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end'
                                                        }}
                                                    >
                                                        <InputLabel htmlFor="bank" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                            {'البنك'}
                                                        </InputLabel>
                                                    </Grid>

                                                    {errors.bank && (
                                                        <Grid xs={12}>
                                                            <FormHelperText
                                                                error
                                                                id="create-payment-voucher-form-helper-text-bank"
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                {errors.bank?.message}
                                                            </FormHelperText>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start'
                                                        }}
                                                    >
                                                        <InputLabel
                                                            htmlFor="cheque_number"
                                                            sx={{
                                                                fontWeight: 700,
                                                                color: 'common.black',
                                                                display: {
                                                                    xs: 'none',
                                                                    sm: 'flex',
                                                                    md: 'flex',
                                                                    lg: 'flex'
                                                                }
                                                            }}
                                                        >
                                                            {'Cheque number'}
                                                        </InputLabel>

                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            pb={0.5}
                                                            sx={{
                                                                display: {
                                                                    xs: 'flex',
                                                                    sm: 'none',
                                                                    md: 'none',
                                                                    lg: 'none'
                                                                }
                                                            }}
                                                        >
                                                            <InputLabel
                                                                htmlFor="cheque_number"
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    color: 'common.black'
                                                                }}
                                                            >
                                                                {'Cheque number'}
                                                            </InputLabel>

                                                            <InputLabel
                                                                htmlFor="cheque_number"
                                                                sx={{ fontWeight: 700, color: 'common.black' }}
                                                            >
                                                                {'رقم الشيك'}
                                                            </InputLabel>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                    >
                                                        <OutlinedInput
                                                            id="cheque_number"
                                                            type="text"
                                                            size="small"
                                                            autoComplete="off"
                                                            disabled={isSubmitting || resourceForm.isSubmitting}
                                                            error={Boolean(errors.cheque_number)}
                                                            fullWidth
                                                            sx={{
                                                                borderRadius: '6px',
                                                                bgcolor: 'common.white',
                                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                            }}
                                                            {...register('cheque_number')}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={{
                                                            display: {
                                                                xs: 'none',
                                                                sm: 'flex',
                                                                md: 'flex',
                                                                lg: 'flex'
                                                            },
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end'
                                                        }}
                                                    >
                                                        <InputLabel htmlFor="cheque_number" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                            {'رقم الشيك'}
                                                        </InputLabel>
                                                    </Grid>

                                                    {errors.cheque_number && (
                                                        <Grid xs={12}>
                                                            <FormHelperText
                                                                error
                                                                id="create-payment-voucher-form-helper-text-cheque-number"
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                {errors.cheque_number?.message}
                                                            </FormHelperText>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={3}
                                                        md={3}
                                                        lg={3}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start'
                                                        }}
                                                    >
                                                        <InputLabel
                                                            htmlFor="cheque_date"
                                                            sx={{
                                                                fontWeight: 700,
                                                                color: 'common.black',
                                                                display: {
                                                                    xs: 'none',
                                                                    sm: 'flex',
                                                                    md: 'flex',
                                                                    lg: 'flex'
                                                                }
                                                            }}
                                                        >
                                                            {'Dated'}
                                                        </InputLabel>

                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            pb={0.5}
                                                            sx={{
                                                                display: {
                                                                    xs: 'flex',
                                                                    sm: 'none',
                                                                    md: 'none',
                                                                    lg: 'none'
                                                                }
                                                            }}
                                                        >
                                                            <InputLabel
                                                                htmlFor="cheque_date"
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    color: 'common.black'
                                                                }}
                                                            >
                                                                {'Dated'}
                                                            </InputLabel>

                                                            <InputLabel
                                                                htmlFor="cheque_date"
                                                                sx={{ fontWeight: 700, color: 'common.black' }}
                                                            >
                                                                {'مؤرخ'}
                                                            </InputLabel>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={6}
                                                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                    >
                                                        <Controller
                                                            name="cheque_date"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <MobileDatePicker
                                                                    format="DD-MM-Y"
                                                                    slotProps={{
                                                                        textField: {
                                                                            disabled: isSubmitting || resourceForm.isSubmitting,
                                                                            autoComplete: 'off',
                                                                            size: 'small',
                                                                            error: Boolean(errors.cheque_date),
                                                                            fullWidth: true
                                                                        }
                                                                    }}
                                                                    sx={{
                                                                        borderRadius: '6px',
                                                                        bgcolor: 'common.white',
                                                                        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                                    }}
                                                                    {...field}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sx={{
                                                            display: {
                                                                xs: 'none',
                                                                sm: 'flex',
                                                                md: 'flex',
                                                                lg: 'flex'
                                                            },
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-end'
                                                        }}
                                                    >
                                                        <InputLabel htmlFor="cheque_date" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                            {'مؤرخ'}
                                                        </InputLabel>
                                                    </Grid>

                                                    {errors.cheque_date && (
                                                        <Grid xs={12}>
                                                            <FormHelperText
                                                                error
                                                                id="create-payment-voucher-form-helper-text-cheque-date"
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                {errors.cheque_date?.message}
                                                            </FormHelperText>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                pt={8}
                                container
                                spacing={{
                                    xs: 2,
                                    sm: 2,
                                    md: 4,
                                    lg: 4
                                }}
                            >
                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <InputLabel
                                                htmlFor="accountant"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: 'common.black',
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    }
                                                }}
                                            >
                                                {'Accountant'}
                                            </InputLabel>

                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                pb={0.5}
                                                sx={{
                                                    width: '100%',
                                                    display: {
                                                        xs: 'flex',
                                                        sm: 'none',
                                                        md: 'none',
                                                        lg: 'none'
                                                    }
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="accountant"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black'
                                                    }}
                                                >
                                                    {'Accountant'}
                                                </InputLabel>

                                                <InputLabel htmlFor="accountant" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                    {'المحاسب'}
                                                </InputLabel>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <OutlinedInput
                                                type="text"
                                                size="small"
                                                readOnly
                                                value={session.data.name}
                                                fullWidth
                                                sx={{
                                                    borderRadius: '6px',
                                                    bgcolor: 'common.white',
                                                    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'flex',
                                                    md: 'flex',
                                                    lg: 'flex'
                                                },
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <InputLabel htmlFor="accountant" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                {'المحاسب'}
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={5} md={5} lg={5}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <InputLabel
                                                htmlFor="receiver_signature"
                                                sx={{
                                                    fontWeight: 700,
                                                    color: 'common.black',
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    }
                                                }}
                                            >
                                                {'Receiver'}
                                            </InputLabel>

                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                pb={0.5}
                                                sx={{
                                                    width: '100%',
                                                    display: {
                                                        xs: 'flex',
                                                        sm: 'none',
                                                        md: 'none',
                                                        lg: 'none'
                                                    }
                                                }}
                                            >
                                                <InputLabel
                                                    htmlFor="receiver_signature"
                                                    sx={{
                                                        fontWeight: 700,
                                                        color: 'common.black'
                                                    }}
                                                >
                                                    {'المستلم'}
                                                </InputLabel>

                                                <InputLabel htmlFor="receiver_signature" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                    {'المستلم'}
                                                </InputLabel>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <OutlinedInput
                                                type="text"
                                                size="small"
                                                disabled
                                                fullWidth
                                                sx={{
                                                    borderRadius: '6px',
                                                    bgcolor: '#f9f9f9',
                                                    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'flex',
                                                    md: 'flex',
                                                    lg: 'flex'
                                                },
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <InputLabel htmlFor="receiver_signature" sx={{ fontWeight: 700, color: 'common.black' }}>
                                                {'المستلم'}
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} lg={2}>
                                    <LoadingButton
                                        size="large"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || resourceForm.isSubmitting}
                                        loading={isSubmitting || resourceForm.isSubmitting}
                                        sx={{
                                            fontWeight: 700
                                        }}
                                    >
                                        {'Submit'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </ScaffoldLayout>
        );
    }

    return null;
};

// ----------------------------------------------------------------------

export default PaymentVouchersCreatePage;
