// redux
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// store
import type { RootState, AppDispatch } from '../store';

// ----------------------------------------------------------------------

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
