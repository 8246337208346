// react
import { useState, useEffect, useRef } from 'react';

// router
import { useParams, useNavigate } from 'react-router-dom';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
// -- icons
import { PrinterOutlined, DownloadOutlined, PhoneOutlined, GlobalOutlined, SendOutlined } from '@ant-design/icons';

// print
import ReactToPrint from 'react-to-print';

// number format
import { NumericFormat } from 'react-number-format';

// hooks
import { useAppSelector } from '../../../hooks/useRedux';

// redux actions
import { initialState as initialVoucherState, PaymentVoucherModel } from '../../../store/paymentVouchers/paymentVouchersSlice';

// routes
// -- constants
import routes from '../../../constants/routes';

// axios
import axios from '../../../containers/axios';

// layouts
import ScaffoldLayout from '../../../layouts/scaffold';

// components
// -- shared
import Image from '../../../components/image';

// images
// -- local
import LogoImage from '../../../assets/images/logo.svg';

// ----------------------------------------------------------------------

const html2pdf = require('html2pdf.js');

// ----------------------------------------------------------------------

interface IResource {
    hasError: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    data: PaymentVoucherModel;
}

// ----------------------------------------------------------------------

const PaymentVouchersEditPage = () => {
    // hooks
    // -- refs
    const printableComponentRef = useRef<HTMLDivElement>(null);
    // -- theme
    const theme = useTheme();
    // -- breakpoints
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- router
    const { slug } = useParams();
    const navigate = useNavigate();
    // -- redux
    const session = useAppSelector((state) => state.session);

    // action state
    const [voucher, setVoucher] = useState<IResource>({
        isLoaded: false,
        isLoading: false,
        hasError: false,
        data: initialVoucherState
    });

    // fetch vouchers
    const fetchVoucher = () => {
        setVoucher({ ...voucher, isLoading: true, isLoaded: false, hasError: false });

        axios
            .get(`payment-vouchers/${slug}`)
            .then((res) => {
                document.title = `${res.data.data.paid_to} - Payment vouchers`;

                setVoucher({
                    ...voucher,
                    hasError: false,
                    isLoading: false,
                    isLoaded: true,
                    data: { ...res.data.data }
                });
            })
            .catch(() => {
                setVoucher({
                    ...voucher,
                    isLoaded: false,
                    isLoading: false,
                    hasError: true
                });
            });
    };

    useEffect(() => {
        document.documentElement!.scrollTop = 0;
        document.scrollingElement!.scrollTop = 0;

        if (session.checked && !session.verified) {
            navigate(routes.authentication.login);
        }

        if (session.checked && session.verified) {
            fetchVoucher();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, navigate]);

    if (session.checked && session.verified) {
        return (
            <ScaffoldLayout backLinkHref={routes.paymentVouchers.search} pageTitle="Payment vouchers">
                <Box
                    mt={8}
                    component={Typography}
                    variant="h3"
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        color: theme.palette.primary.main,
                        fontWeight: 700,
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: isSmDown ? '22px' : isMdDown ? '28px' : '32px'
                    }}
                >
                    {'REVIEW PAYMENT VOUCHER'}
                </Box>

                <Box mt={5} px={isMdDown ? 2 : 20}>
                    <Box sx={{ boxShadow: theme.shadows[5] }}>
                        <Box sx={{ py: 8, px: isSmDown ? 2 : 5 }} ref={printableComponentRef}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Stack direction="row" spacing={3} alignItems="center" sx={{ width: '100%' }}>
                                    <Image alt="" src={LogoImage} sx={{ width: '80px', height: '70px' }} />

                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontSize: isSmDown ? '18px' : isMdDown ? '22px' : '26px',
                                            fontWeight: 700,
                                            display: {
                                                xs: 'none',
                                                sm: 'flex',
                                                md: 'flex',
                                                lg: 'flex'
                                            }
                                        }}
                                    >
                                        {'MEDSHIP FEEDERS DMCC'}
                                    </Typography>
                                </Stack>

                                <Box component={Typography} variant="subtitle2" sx={{ fontWeight: 700 }}>
                                    {
                                        'Office No. 1010, 1009, Clover Bay Tower , Busniess Bay, Burj Khalifa Zone, Dubai - United Arab Emirates Tel: +97142941007'
                                    }
                                </Box>
                            </Stack>

                            <Box component={Divider} sx={{ bgcolor: '#243E95', height: '5px', mt: 4 }} />

                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Box
                                    component={Typography}
                                    variant="subtitle1"
                                    mt={10}
                                    p={1}
                                    sx={{
                                        fontWeight: 700,
                                        border: `2px solid ${theme.palette.primary.main}`,
                                        width: 'fit-content'
                                    }}
                                >
                                    {'PAYMENT VOUCHER'}
                                </Box>
                            </Box>

                            <Box
                                component={Typography}
                                variant="subtitle1"
                                mt={2}
                                sx={{
                                    fontWeight: 700,
                                    textAlign: 'center'
                                }}
                            >
                                {'سند صرف'}
                            </Box>

                            {voucher.isLoading || !voucher.isLoaded || voucher.hasError ? (
                                <Box mt={5}>
                                    <Stack mb={2} spacing={50} direction="row" alignItems="center" justifyContent="space-between">
                                        <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                        <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                    </Stack>

                                    <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                    <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                    <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                    <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                    <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                    <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                    <Skeleton animation="wave" sx={{ width: '100%', height: '40px' }} />
                                </Box>
                            ) : (
                                <>
                                    <Stack
                                        mt={8}
                                        direction={isSmDown ? 'column' : 'row'}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1.5}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                {'Date'}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    fontWeight: 600,
                                                    color: '#243E95',
                                                    textDecoration: 'underline',
                                                    textDecorationColor: '#E7E7E7'
                                                }}
                                            >
                                                {voucher.data.voucher_date}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                {'التاريخ'}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'R NO.'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#243E95',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#E7E7E7'
                                                    }}
                                                >
                                                    {`#${voucher.data.reference_number}`}
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'ر. مرجعي'}
                                                </Typography>
                                            </Stack>

                                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'Dhs'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#243E95',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#E7E7E7'
                                                    }}
                                                >
                                                    <NumericFormat displayType="text" decimalScale={0} value={voucher.data.dhs} />
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'درهم'}
                                                </Typography>
                                            </Stack>

                                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'Fils'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#243E95',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#E7E7E7'
                                                    }}
                                                >
                                                    <NumericFormat
                                                        displayType="text"
                                                        decimalScale={0}
                                                        value={voucher.data.fils}
                                                        prefix="."
                                                    />
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'فلس'}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <Grid container mt={5}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'Received from M/s.'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {voucher.data.paid_to}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'تصرف الى السادة'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={3}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'The Sum of'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {voucher.data.sum_of}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'مبلغ وقدره (درهم)'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={3}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'On settlement of'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {`#${voucher.data.on_settlement_of}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'وذلك عن'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={3}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'Payment type'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {voucher.data.payment_type === 'bank' ? 'Bank' : 'Cash'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'طريقة الدفع'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {voucher.data.payment_type === 'bank' && (
                                        <>
                                            <Grid container mt={3}>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'Bank'}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                        {voucher.data.bank}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'اسم البنك'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container mt={3}>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'Cheque No.'}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                        {voucher.data.cheque_number}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'رقم الشيك'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container mt={3}>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'Dated'}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                        {voucher.data.cheque_date}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'تاريخ الشيك'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}

                            <Grid container mt={voucher.data.payment_type === 'cash' ? 20 : 5}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    sx={{ display: 'flex', justifyContent: isSmDown ? 'center' : 'flex-start' }}
                                >
                                    <Stack spacing={1}>
                                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                            {voucher.data.user.name}
                                        </Typography>

                                        <Divider />

                                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                            {'Accountant'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    mt={isSmDown ? 15 : 0}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                        {`Typed By ${session.data.name}`}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    mt={isSmDown ? 15 : 0}
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: isSmDown ? 'center' : 'flex-end',
                                        alignItems: isSmDown ? 'center' : 'flex-end'
                                    }}
                                >
                                    <Stack spacing={1}>
                                        <Divider />

                                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                            {'SIGNATURE'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Box component={Divider} sx={{ bgcolor: '#243E95', mt: 5, height: '3px' }} />

                            <Grid container mt={isSmDown ? 1 : 3} rowSpacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    sx={{ display: 'flex', justifyContent: isSmDown ? 'center' : 'flex-start' }}
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <PhoneOutlined style={{ fontSize: '20px', color: '#01B6F2' }} />

                                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                            {'+97 142941007'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <GlobalOutlined style={{ fontSize: '20px', color: '#01B6F2' }} />

                                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                            {'www.shipfeedersdmcc.com'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: isSmDown ? 'center' : 'flex-end',
                                        alignItems: isSmDown ? 'center' : 'flex-end'
                                    }}
                                >
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <SendOutlined style={{ fontSize: '20px', color: '#01B6F2' }} />

                                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                            {'info@shipfeedersdmcc.com'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>

                <Stack mt={7} spacing={4} direction="row" justifyContent="center" alignItems="center">
                    <ReactToPrint
                        trigger={() => (
                            <IconButton
                                color="primary"
                                disabled={voucher.isLoading || !voucher.isLoaded || voucher.hasError}
                                sx={{ background: '#fff', boxShadow: theme.shadows[3], borderRadius: '7px' }}
                            >
                                <PrinterOutlined style={{ fontSize: '24px' }} />
                            </IconButton>
                        )}
                        content={() => printableComponentRef.current}
                    />

                    <ReactToPrint
                        trigger={() => (
                            <IconButton
                                color="primary"
                                disabled={voucher.isLoading || !voucher.isLoaded || voucher.hasError}
                                sx={{ background: '#fff', boxShadow: theme.shadows[3], borderRadius: '7px' }}
                            >
                                <DownloadOutlined style={{ fontSize: '24px' }} />
                            </IconButton>
                        )}
                        content={() => printableComponentRef.current}
                        print={async (printIframe: HTMLIFrameElement) => {
                            const document = printIframe.contentDocument;

                            if (document) {
                                const html = document.getElementsByTagName('html')[0];

                                await html2pdf()
                                    .set({
                                        filename: `${voucher.data.paid_to}.pdf`,
                                        image: { type: 'jpeg', quality: 1 },
                                        html2canvas: {
                                            dpi: 192,
                                            scale: 4,
                                            letterRendering: true,
                                            useCORS: true
                                        },
                                        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                                    })
                                    .from(html)
                                    .to('pdf')
                                    .save();
                            }
                        }}
                    />
                </Stack>
            </ScaffoldLayout>
        );
    }

    return null;
};

// ----------------------------------------------------------------------

export default PaymentVouchersEditPage;
