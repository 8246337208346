const Avatar = () => {
    return {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default Avatar;
