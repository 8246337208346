// react
import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

const getStorageValue = (key: string, defaultValue: string | object) => {
    if (typeof window !== 'undefined') {
        const saved = localStorage.getItem(key);

        const initial = saved !== null ? JSON.parse(saved) : defaultValue;

        return initial;
    }
};

// ----------------------------------------------------------------------

const useLocalStorage = (key: string, defaultValue: string | object) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
};

// ----------------------------------------------------------------------

export default useLocalStorage;
