const Drawer = () => {
    return {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#081627'
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default Drawer;
