// react
import { useState, useEffect, MouseEvent, ChangeEvent } from 'react';

// router
import { useNavigate } from 'react-router-dom';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- components
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

// hooks
import { useAppSelector } from '../../../hooks/useRedux';

// routes
// -- constants
import routes from '../../../constants/routes';

// layouts
import ScaffoldLayout from '../../../layouts/scaffold';

// components
// -- shared
import Greeting from '../../../components/greeting';
import ActionOption from '../../../components/actionOption';
import Radio from '../../../components/radio';

// ----------------------------------------------------------------------

const PaymentVouchersActionsPage = () => {
    // hooks
    // -- theme
    const theme = useTheme();
    // -- breakpoints
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- router
    const navigate = useNavigate();
    // -- redux
    const session = useAppSelector((state) => state.session);

    // action state
    const [action, setAction] = useState<string>('search');

    // action callback(s)
    // -- handle change
    const handleActionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setAction(event.target.value);
    };

    // next push callback
    const handleNext = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (action === 'search') {
            navigate(routes.paymentVouchers.search);
        } else {
            navigate(routes.paymentVouchers.create);
        }
    };

    useEffect(() => {
        if (session.checked && !session.verified) {
            navigate(routes.authentication.login);
        }
    }, [session, navigate]);

    if (session.checked && session.verified) {
        return (
            <ScaffoldLayout showSideImages backLinkHref={routes.dashboard} pageTitle="Payment vouchers">
                <Greeting />

                <Box sx={{ opacity: 0.9, px: isSmDown ? 5 : isMdDown ? 10 : 40 }}>
                    <RadioGroup value={action} onChange={handleActionChange}>
                        <Stack mt={10} spacing={2.5}>
                            <ActionOption selected={action === 'search'}>
                                <FormControlLabel
                                    value="search"
                                    control={<Radio />}
                                    sx={{ py: 3, mx: 0, width: '100%' }}
                                    disableTypography
                                    label={
                                        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                                            <Typography
                                                variant="h6"
                                                color="primary"
                                                sx={{
                                                    pl: 2,
                                                    fontWeight: 700,
                                                    fontSize: isSmDown ? '22px' : isMdDown ? '28px' : '33px',
                                                    lineHeight: 1
                                                }}
                                            >
                                                {'Search'}
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                color="primary"
                                                sx={{
                                                    pr: 2,
                                                    fontWeight: 700,
                                                    fontSize: isSmDown ? '22px' : isMdDown ? '28px' : '33px',
                                                    lineHeight: 1
                                                }}
                                            >
                                                {'بحث'}
                                            </Typography>
                                        </Stack>
                                    }
                                />
                            </ActionOption>

                            <ActionOption selected={action === 'create'}>
                                <FormControlLabel
                                    value="create"
                                    control={<Radio />}
                                    sx={{ py: 3, mx: 0, width: '100%' }}
                                    disableTypography
                                    label={
                                        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
                                            <Typography
                                                variant="h6"
                                                color="primary"
                                                sx={{
                                                    pl: 2,
                                                    fontWeight: 700,
                                                    fontSize: isSmDown ? '22px' : isMdDown ? '28px' : '33px',
                                                    lineHeight: 1
                                                }}
                                            >
                                                {'Create'}
                                            </Typography>

                                            <Typography
                                                variant="h6"
                                                color="primary"
                                                sx={{
                                                    pr: 2,
                                                    fontWeight: 700,
                                                    fontSize: isSmDown ? '22px' : isMdDown ? '28px' : '33px',
                                                    lineHeight: 1
                                                }}
                                            >
                                                {'إنشاء'}
                                            </Typography>
                                        </Stack>
                                    }
                                />
                            </ActionOption>
                        </Stack>
                    </RadioGroup>
                </Box>

                <Box mt={8} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onClick={handleNext}
                        variant="contained"
                        color="primary"
                        sx={{ height: '46px', px: isMdDown ? 10 : 20, textTransform: 'none' }}
                    >
                        {'Next'}
                    </Button>
                </Box>
            </ScaffoldLayout>
        );
    }

    return null;
};

// ----------------------------------------------------------------------

export default PaymentVouchersActionsPage;
