const Button = () => {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none'
                    }
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default Button;
