// react
import { ReactNode } from 'react';

// prop types
import PropTypes from 'prop-types';

// @mui
// -- system
import { ThemeOptions, Spacing, Direction } from '@mui/system';
// -- styles
import { createTheme, ThemeProvider as MuiThemeProvider, Shadows, Mixins, TransitionsOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
// -- components
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

// overrides
import mixins from './mixins';
import palette from './palette';
import shape from './shape';
import typography from './typography';
import Components from './components';

// ----------------------------------------------------------------------

interface ThemeOptionsProps extends ThemeOptions {
    mixins: Mixins;
    palette: Record<string, any>;
    shadows?: Shadows | undefined;
    transitions?: TransitionsOptions | undefined;
    typography: TypographyOptions;
    spacing?: Spacing;
}

interface ThemeProviderProps {
    children?: ReactNode;
}

// ----------------------------------------------------------------------

const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const themeOptions: ThemeOptionsProps = {
        direction: 'ltr' as Direction,
        palette: { ...palette },
        shape: { ...shape },
        typography: { ...typography },
        mixins: { ...mixins }
    };

    const theme = createTheme(themeOptions);
    theme.components = Components(theme);

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </StyledEngineProvider>
    );
};

ThemeProvider.propTypes = {
    children: PropTypes.node
};

// ----------------------------------------------------------------------

export default ThemeProvider;
