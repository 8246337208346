// redux toolkit
import { createAsyncThunk } from '@reduxjs/toolkit';

// axios
import axios from '../../containers/axios';

// types
// -- user
import { initialState as initialUserState, UserModel } from '../session/sessionSlice';

// ----------------------------------------------------------------------

export interface ICreateReceiptVoucherFormFields {
    voucher_date: string | undefined;
    fils: number | undefined;
    dhs: number | undefined;
    received_from: string;
    sum_of: string;
    on_settlement_of: string;
    payment_type: string;
    cheque_number: string;
    bank: string;
    cheque_date: string;
    media: File | undefined;
}

export interface ReceiptVoucherModel {
    id: string;
    reference_number: number;
    voucher_date: string;
    fils: number;
    dhs: number;
    slug: string;
    received_from: string;
    sum_of: string;
    on_settlement_of: string;
    payment_type: string;
    cheque_number: string | null;
    bank: string | null;
    cheque_date: string | null;
    media: string;
    user: UserModel;
}

// ----------------------------------------------------------------------

export const initialState = {
    id: '',
    reference_number: 0,
    voucher_date: '',
    fils: 0,
    dhs: 0,
    slug: '',
    received_from: '',
    sum_of: '',
    on_settlement_of: '',
    payment_type: '',
    cheque_number: '',
    bank: '',
    cheque_date: '',
    media: '',
    user: { ...initialUserState.data }
};

// ----------------------------------------------------------------------

export const create = createAsyncThunk('vouchers.receipt/create', (data: ICreateReceiptVoucherFormFields, { rejectWithValue }) => {
    const formData = new FormData();

    formData.append('_method', 'PUT');

    formData.append('dhs', `${data.dhs}`);
    formData.append('fils', `${data.fils}`);
    formData.append('voucher_date', data.voucher_date ?? '');
    formData.append('received_from', data.received_from);
    formData.append('sum_of', data.sum_of);
    formData.append('on_settlement_of', data.on_settlement_of);
    formData.append('payment_type', data.payment_type);

    if (data.payment_type === 'bank') {
        formData.append('bank', data.bank);
        formData.append('cheque_number', data.cheque_number);
        formData.append('cheque_date', data.cheque_date);
    }

    if (data.media) {
        formData.append('media', data.media as Blob);
    }

    return axios
        .post('receipt-vouchers', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => res)
        .catch((err) => rejectWithValue({ status: err.response.status, statusText: err.response.statusText, data: err.response.data }));
});
