// react
import { useState, useEffect, useRef } from 'react';

// router
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- lab
import { LoadingButton } from '@mui/lab';
// -- components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
// -- icons
import {
    PhoneOutlined,
    GlobalOutlined,
    SendOutlined,
    CopyOutlined,
    EyeOutlined,
    PrinterOutlined,
    DownloadOutlined,
    FrownOutlined
} from '@ant-design/icons';

// number format
import { NumericFormat } from 'react-number-format';

// print
import ReactToPrint from 'react-to-print';

// forms
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

// hooks
import { useAppSelector } from '../../../hooks/useRedux';

// redux actions
import { ReceiptVoucherModel } from '../../../store/receiptVouchers/receiptVouchersSlice';

// routes
// -- constants
import routes from '../../../constants/routes';

// axios
import axios from '../../../containers/axios';

// layouts
import ScaffoldLayout from '../../../layouts/scaffold';

// components
// -- shared
import { DefaultIcon, CheckedIcon } from '../../../components/radio';

// images
// -- local
import LogoImage from '../../../assets/images/logo.svg';

// ----------------------------------------------------------------------

const html2pdf = require('html2pdf.js');

// ----------------------------------------------------------------------

interface ISearchFormFields {
    date_from: string;
    date_to: string;
    received_from: string;
    cheque: string;
    bank_name: string;
}

interface IResources {
    isLoading: boolean;
    isLoaded: boolean;
    hasError: boolean;
    data: ReceiptVoucherModel[];
}

// ----------------------------------------------------------------------

const ReceiptVouchersSearchPage = () => {
    // state
    // -- vouchers
    const [vouchers, setVouchers] = useState<IResources>({
        isLoading: false,
        isLoaded: false,
        hasError: false,
        data: []
    });

    // hooks
    // -- refs
    const printableComponentsRefs = useRef(new Array(vouchers.data.length));
    // -- theme
    const theme = useTheme();
    // -- breakpoints
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- router
    const navigate = useNavigate();
    // -- redux
    const session = useAppSelector((state) => state.session);
    // -- forms
    const {
        register,
        control,
        handleSubmit,
        formState: { isSubmitting }
    } = useForm<ISearchFormFields>({
        mode: 'onTouched'
    });

    // fetch vouchers
    const fetchVouchers = (searchQuery: ISearchFormFields | undefined = undefined) => {
        setVouchers({ ...vouchers, isLoading: true, isLoaded: false, hasError: false });

        const parsedSearchQueryDateFrom = (searchQuery?.date_from as string) || null;
        const parsedSearchQueryDateTo = (searchQuery?.date_to as string) || null;
        const parsedSearchQueryReceivedFrom = searchQuery?.received_from || null;
        const parsedSearchQueryCheque = searchQuery?.cheque || null;
        const parsedSearchQueryBankName = searchQuery?.bank_name || null;

        axios
            .get('receipt-vouchers', {
                params: {
                    date_from: parsedSearchQueryDateFrom ? encodeURI(parsedSearchQueryDateFrom) : null,
                    date_to: parsedSearchQueryDateTo ? encodeURI(parsedSearchQueryDateTo) : null,
                    received_from: parsedSearchQueryReceivedFrom ? encodeURI(parsedSearchQueryReceivedFrom) : null,
                    cheque: parsedSearchQueryCheque ? encodeURI(parsedSearchQueryCheque) : null,
                    bank_name: parsedSearchQueryBankName ? encodeURI(parsedSearchQueryBankName) : null
                }
            })
            .then((res) => {
                setVouchers({
                    ...vouchers,
                    hasError: false,
                    isLoading: false,
                    isLoaded: true,
                    data: [...res.data.data]
                });
            })
            .catch(() => {
                setVouchers({
                    ...vouchers,
                    isLoaded: false,
                    isLoading: false,
                    hasError: true
                });
            });
    };

    // search form callback(s)
    const handleSearch: SubmitHandler<ISearchFormFields> = async (data: ISearchFormFields) => {
        setVouchers({ ...vouchers, isLoading: true, isLoaded: false, hasError: false });

        fetchVouchers(data);
    };

    useEffect(() => {
        document.documentElement!.scrollTop = 0;
        document.scrollingElement!.scrollTop = 0;

        if (session.checked && !session.verified) {
            navigate(routes.authentication.login);
        }

        if (session.checked && session.verified) {
            fetchVouchers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, navigate]);

    if (session.checked && session.verified) {
        return (
            <ScaffoldLayout showSideImages backLinkHref={routes.receiptVouchers.actions} pageTitle="Search - Receipt vouchers">
                <Box px={isMdDown ? 2 : 18}>
                    <Stack
                        mt={5}
                        py={3}
                        px={2}
                        spacing={3}
                        sx={{ background: '#F9F9F9', opacity: 0.9, border: '1px solid #DFDFDF', borderRadius: '9px' }}
                    >
                        <Box
                            component={Typography}
                            color="common.black"
                            sx={{ fontWeight: 700, lineHeight: '17px', fontSize: '14px', alignSelf: 'center' }}
                        >
                            {'Search by'}
                        </Box>

                        <form onSubmit={handleSubmit(handleSearch)}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={2}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="date_from" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
                                            {'Date (from)'}
                                        </InputLabel>

                                        <Controller
                                            name="date_from"
                                            control={control}
                                            render={({ field }) => (
                                                <MobileDatePicker
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small',
                                                            fullWidth: true
                                                        }
                                                    }}
                                                    sx={{
                                                        borderRadius: '6px',
                                                        bgcolor: 'common.white',
                                                        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={2}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="date_to" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
                                            {'Date (to)'}
                                        </InputLabel>

                                        <Controller
                                            name="date_to"
                                            control={control}
                                            render={({ field }) => (
                                                <MobileDatePicker
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small',
                                                            fullWidth: true
                                                        }
                                                    }}
                                                    sx={{
                                                        borderRadius: '6px',
                                                        bgcolor: 'common.white',
                                                        boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                                    }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={2}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="received_from" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
                                            {'Received from M/s.'}
                                        </InputLabel>

                                        <OutlinedInput
                                            id="received_from"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            disabled={isSubmitting || vouchers.isLoading}
                                            sx={{
                                                borderRadius: '6px',
                                                bgcolor: 'common.white',
                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                            }}
                                            {...register('received_from')}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={2}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="cheque" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
                                            {'Cheque number'}
                                        </InputLabel>

                                        <OutlinedInput
                                            id="cheque"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            disabled={isSubmitting || vouchers.isLoading}
                                            sx={{
                                                borderRadius: '6px',
                                                bgcolor: 'common.white',
                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                            }}
                                            {...register('cheque')}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={2}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="bank_name" sx={{ fontWeight: 700, color: theme.palette.primary.main }}>
                                            {'Bank name'}
                                        </InputLabel>

                                        <OutlinedInput
                                            id="bank_name"
                                            type="text"
                                            size="small"
                                            fullWidth
                                            disabled={isSubmitting || vouchers.isLoading}
                                            sx={{
                                                borderRadius: '6px',
                                                bgcolor: 'common.white',
                                                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.05)'
                                            }}
                                            {...register('bank_name')}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <LoadingButton
                                        size="large"
                                        type="submit"
                                        fullWidth
                                        disabled={isSubmitting || vouchers.isLoading}
                                        loading={isSubmitting || vouchers.isLoading}
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            fontWeight: 700
                                        }}
                                    >
                                        {'SEARCH'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Stack>
                </Box>

                <Box px={isMdDown ? 2 : 18}>
                    <Stack
                        mt={5}
                        py={3}
                        px={2}
                        spacing={3}
                        sx={{ background: '#F9F9F9', opacity: 0.9, border: '1px solid #DFDFDF', borderRadius: '9px' }}
                    >
                        <Box
                            component={Typography}
                            color="common.black"
                            sx={{ fontWeight: 700, lineHeight: '17px', fontSize: '14px', alignSelf: 'center' }}
                        >
                            {vouchers.isLoading ? 'Loading...' : `${vouchers.data.length} receipts found`}
                        </Box>

                        {!vouchers.isLoading && vouchers.data.length === 0 && (
                            <Stack spacing={2} alignItems="center" justifyContent="center">
                                <FrownOutlined style={{ fontSize: '32px', color: theme.palette.primary.main }} />

                                <Button
                                    variant="contained"
                                    component={ReactRouterLink}
                                    to={routes.receiptVouchers.create}
                                    sx={{ maxWidth: 'fit-content' }}
                                >
                                    {'Create a voucher'}
                                </Button>
                            </Stack>
                        )}

                        {vouchers.isLoading && (
                            <>
                                <Skeleton variant="rounded" width="100%" height={102} />
                                <Skeleton variant="rounded" width="100%" height={102} />
                                <Skeleton variant="rounded" width="100%" height={102} />
                                <Skeleton variant="rounded" width="100%" height={102} />
                            </>
                        )}

                        {vouchers.data.map((voucher, index) => (
                            <div key={voucher.id}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Grid
                                        container
                                        py={4}
                                        px={2}
                                        sx={{ background: '#fff', boxShadow: theme.shadows[3], borderRadius: '10px', width: '100%' }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={3}
                                                sx={{
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    }
                                                }}
                                            >
                                                <Box
                                                    component={(index + 1) % 2 === 0 ? DefaultIcon : CheckedIcon}
                                                    sx={{
                                                        maxWidth: '38px',
                                                        maxHeight: '38px',
                                                        display: {
                                                            xs: 'none',
                                                            sm: 'none',
                                                            md: 'flex',
                                                            lg: 'flex'
                                                        }
                                                    }}
                                                />

                                                <Box
                                                    component={Typography}
                                                    variant="body2"
                                                    sx={{ fontSize: '14px', fontWeight: 700, color: theme.palette.primary.main }}
                                                >
                                                    {'Payment'}
                                                </Box>

                                                <Box
                                                    component={CopyOutlined}
                                                    style={{ fontSize: '30px', color: theme.palette.primary.main }}
                                                />
                                            </Stack>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            md={4}
                                            lg={4}
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <Box
                                                component={Typography}
                                                variant="body2"
                                                color="common.black"
                                                sx={{ fontSize: '14px', fontWeight: 700 }}
                                            >
                                                {voucher.received_from}
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <Box
                                                component={Typography}
                                                variant="body2"
                                                color="common.black"
                                                sx={{ fontSize: '14px', fontWeight: 700 }}
                                            >
                                                {voucher.cheque_number ?? '-'}
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <Box
                                                component={Typography}
                                                variant="body2"
                                                color="common.black"
                                                sx={{ fontSize: '14px', fontWeight: 700 }}
                                            >
                                                {voucher.voucher_date}
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <Box
                                                component={Typography}
                                                variant="body2"
                                                color="common.black"
                                                sx={{ fontSize: '14px', fontWeight: 700 }}
                                            >
                                                {`${voucher.dhs}.${voucher.fils} AED`}
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Stack spacing={1}>
                                        <IconButton
                                            color="primary"
                                            component={ReactRouterLink}
                                            to={routes.paymentVouchers.edit.replace(':slug', voucher.slug)}
                                            sx={{ background: '#fff', boxShadow: theme.shadows[3], borderRadius: '7px' }}
                                        >
                                            <EyeOutlined style={{ fontSize: '12px' }} />
                                        </IconButton>

                                        <ReactToPrint
                                            trigger={() => (
                                                <IconButton
                                                    color="primary"
                                                    sx={{ background: '#fff', boxShadow: theme.shadows[3], borderRadius: '7px' }}
                                                >
                                                    <PrinterOutlined style={{ fontSize: '12px' }} />
                                                </IconButton>
                                            )}
                                            content={() => printableComponentsRefs.current[index]}
                                        />

                                        <ReactToPrint
                                            trigger={() => (
                                                <IconButton
                                                    color="primary"
                                                    sx={{ background: '#fff', boxShadow: theme.shadows[3], borderRadius: '7px' }}
                                                >
                                                    <DownloadOutlined style={{ fontSize: '12px' }} />
                                                </IconButton>
                                            )}
                                            content={() => printableComponentsRefs.current[index]}
                                            print={async (printIframe: HTMLIFrameElement) => {
                                                const document = printIframe.contentDocument;

                                                if (document) {
                                                    const content = document.getElementById(`printable-section-${index}`);
                                                    content!.style.display = 'block';

                                                    await html2pdf()
                                                        .set({
                                                            filename: `${voucher.received_from}.pdf`,
                                                            image: { type: 'jpeg', quality: 1 },
                                                            html2canvas: {
                                                                dpi: 192,
                                                                scale: 4,
                                                                logging: true,
                                                                letterRendering: true,
                                                                useCORS: true,
                                                                onclone: function (doc: any) {
                                                                    content!.style.display = 'block !important';
                                                                }
                                                            },
                                                            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
                                                        })
                                                        .from(content)
                                                        .to('pdf')
                                                        .save();
                                                }
                                            }}
                                        />
                                    </Stack>
                                </Stack>

                                <Box
                                    id={`printable-section-${index}`}
                                    className={`printable-section-${index}`}
                                    displayPrint="block"
                                    sx={{ display: 'none', py: 8, px: isSmDown ? 2 : 5 }}
                                    ref={(el) => (printableComponentsRefs.current[index] = el)}
                                >
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Stack direction="row" spacing={3} alignItems="center" sx={{ width: '100%' }}>
                                            <img alt="" src={LogoImage} style={{ width: '80px', height: '70px' }} />

                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    fontSize: isSmDown ? '18px' : isMdDown ? '22px' : '26px',
                                                    fontWeight: 700,
                                                    display: {
                                                        xs: 'none',
                                                        sm: 'flex',
                                                        md: 'flex',
                                                        lg: 'flex'
                                                    }
                                                }}
                                            >
                                                {'MEDSHIP FEEDERS DMCC'}
                                            </Typography>
                                        </Stack>

                                        <Box component={Typography} variant="subtitle2" sx={{ fontWeight: 700 }}>
                                            {
                                                'Office No. 1010, 1009, Clover Bay Tower , Busniess Bay, Burj Khalifa Zone, Dubai - United Arab Emirates Tel: +97142941007'
                                            }
                                        </Box>
                                    </Stack>

                                    <Box component={Divider} sx={{ bgcolor: '#243E95', height: '5px', mt: 4 }} />

                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Box
                                            component={Typography}
                                            variant="subtitle1"
                                            mt={10}
                                            p={1}
                                            sx={{
                                                fontWeight: 700,
                                                border: `2px solid ${theme.palette.primary.main}`,
                                                width: 'fit-content'
                                            }}
                                        >
                                            {'RECEIPT VOUCHER'}
                                        </Box>
                                    </Box>

                                    <Box
                                        component={Typography}
                                        variant="subtitle1"
                                        mt={2}
                                        sx={{
                                            fontWeight: 700,
                                            textAlign: 'center'
                                        }}
                                    >
                                        {'سند قبض'}
                                    </Box>

                                    <Stack
                                        mt={8}
                                        direction={isSmDown ? 'column' : 'row'}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1.5}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                {'Date'}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    fontWeight: 600,
                                                    color: '#243E95',
                                                    textDecoration: 'underline',
                                                    textDecorationColor: '#E7E7E7'
                                                }}
                                            >
                                                {voucher.voucher_date}
                                            </Typography>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                {'التاريخ'}
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'R NO.'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#243E95',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#E7E7E7'
                                                    }}
                                                >
                                                    {`#${voucher.reference_number}`}
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'ر. مرجعي'}
                                                </Typography>
                                            </Stack>

                                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'Dhs'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#243E95',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#E7E7E7'
                                                    }}
                                                >
                                                    <NumericFormat displayType="text" decimalScale={0} value={voucher.dhs} />
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'درهم'}
                                                </Typography>
                                            </Stack>

                                            <Stack direction="row" alignItems="center" spacing={1.5}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'Fils'}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                        fontWeight: 600,
                                                        color: '#243E95',
                                                        textDecoration: 'underline',
                                                        textDecorationColor: '#E7E7E7'
                                                    }}
                                                >
                                                    <NumericFormat displayType="text" decimalScale={0} value={voucher.fils} prefix="." />
                                                </Typography>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                                    {'فلس'}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>

                                    <Grid container mt={5}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'Received from M/s.'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {voucher.received_from}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'نستلم من السادة'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={3}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'The Sum of'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {voucher.sum_of}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'مبلغ وقدره (درهم)'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={3}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'On settlement of'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {`#${voucher.on_settlement_of}`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'وذلك عن'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container mt={3}>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'Payment type'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                {voucher.payment_type === 'bank' ? 'Bank' : 'Cash'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {'طريقة الدفع'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {voucher.payment_type === 'bank' && (
                                        <>
                                            <Grid container mt={3}>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'Bank'}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                        {voucher.bank}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'اسم البنك'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container mt={3}>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'Cheque No.'}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                        {voucher.cheque_number}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'رقم الشيك'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container mt={3}>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'Dated'}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    sx={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #E7E7E7' }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700, color: '#243E95' }}>
                                                        {voucher.cheque_date}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                        {'تاريخ الشيك'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}

                                    <Grid container mt={voucher.payment_type === 'cash' ? 20 : 5}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{ display: 'flex', justifyContent: isSmDown ? 'center' : 'flex-start' }}
                                        >
                                            <Stack spacing={1}>
                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                    {voucher.user.name}
                                                </Typography>

                                                <Divider />

                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                    {'Accountant'}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            mt={isSmDown ? 15 : 0}
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                {`Typed By ${session.data.name}`}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            mt={isSmDown ? 15 : 0}
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: isSmDown ? 'center' : 'flex-end',
                                                alignItems: isSmDown ? 'center' : 'flex-end'
                                            }}
                                        >
                                            <Stack spacing={1}>
                                                <Divider />

                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                    {'SIGNATURE'}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    <Box component={Divider} sx={{ bgcolor: '#243E95', mt: 5, height: '3px' }} />

                                    <Grid container mt={isSmDown ? 1 : 3} rowSpacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{ display: 'flex', justifyContent: isSmDown ? 'center' : 'flex-start' }}
                                        >
                                            <Stack spacing={1} direction="row" alignItems="center">
                                                <PhoneOutlined style={{ fontSize: '20px', color: '#01B6F2' }} />

                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                    {'+97 142941007'}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            <Stack spacing={1} direction="row" alignItems="center">
                                                <GlobalOutlined style={{ fontSize: '20px', color: '#01B6F2' }} />

                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                    {'www.shipfeedersdmcc.com'}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={3}
                                            md={3}
                                            lg={3}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: isSmDown ? 'center' : 'flex-end',
                                                alignItems: isSmDown ? 'center' : 'flex-end'
                                            }}
                                        >
                                            <Stack spacing={1} direction="row" alignItems="center">
                                                <SendOutlined style={{ fontSize: '20px', color: '#01B6F2' }} />

                                                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                                                    {'info@shipfeedersdmcc.com'}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </div>
                        ))}
                    </Stack>
                </Box>
            </ScaffoldLayout>
        );
    }

    return null;
};

// ----------------------------------------------------------------------

export default ReceiptVouchersSearchPage;
