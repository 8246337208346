// @mui
// -- system
import { Theme } from '@mui/system/createTheme';
import { Spacing } from '@mui/system/createTheme/createSpacing';

// ----------------------------------------------------------------------

interface TabProps extends Theme {
    spacing: Spacing;
}

// ----------------------------------------------------------------------

const Tab = (theme: TabProps) => {
    return {
        MuiTab: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    margin: '0 16px',
                    minWidth: 0,
                    padding: 0,
                    [theme.breakpoints.up('md')]: {
                        padding: 0,
                        minWidth: 0
                    }
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default Tab;
