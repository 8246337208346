// react
import { useState } from 'react';

// router
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- lab
import { LoadingButton } from '@mui/lab';
// -- components
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiLink from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
// -- icons
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

// forms
import { useForm, SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

// routes
// -- constants
import routes from '../../../constants/routes';

// hooks
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';

// redux
// -- actions
import { authenticate, ILoginFormFields } from '../../../store/session/sessionSlice';

// layouts
import ScaffoldLayout from '../../../layouts/scaffold';

// components
// -- shared
import Image from '../../../components/image';

// images
// -- local
import ShippingGroupImage from '../../../assets/images/3mfAtY.png';
import CloudImage from '../../../assets/images/Huvy7f.svg';

// ----------------------------------------------------------------------

interface ILoginFormState {
    error: boolean;
    invalidAttempts: boolean;
    isSubmitting: boolean;
}

// ----------------------------------------------------------------------

const schema = Joi.object({
    email: Joi.string().required(),
    password: Joi.string().required()
});

// ----------------------------------------------------------------------

const LoginPage = () => {
    // hooks
    // -- theme
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- router
    const navigate = useNavigate();
    // -- redux
    const dispatch = useAppDispatch();
    const session = useAppSelector((state) => state.session);
    // -- forms
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<ILoginFormFields>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: joiResolver(schema)
    });

    // state
    // -- login form
    const [loginForm, setLoginForm] = useState<ILoginFormState>({
        error: false,
        invalidAttempts: false,
        isSubmitting: false
    });
    // -- password visibility
    const [showPassword, setShowPassword] = useState<boolean>(false);

    // password visibility callback(s)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // form callback(s)
    const handleLogin: SubmitHandler<ILoginFormFields> = async (data: ILoginFormFields) => {
        setLoginForm({
            ...loginForm,
            error: false,
            invalidAttempts: false,
            isSubmitting: true
        });

        dispatch(authenticate(data))
            .unwrap()
            .then((res) => {
                navigate(routes.dashboard);
            })
            .catch((err) => {
                if (err.code === 'ERR_BAD_REQUEST') {
                    setLoginForm({
                        ...loginForm,
                        error: false,
                        invalidAttempts: true,
                        isSubmitting: false
                    });
                } else {
                    setLoginForm({
                        ...loginForm,
                        error: true,
                        invalidAttempts: false,
                        isSubmitting: false
                    });
                }
            });
    };

    if (session.checked && session.verified) {
        navigate(routes.dashboard);
    }

    if (session.checked && !session.verified) {
        return (
            <ScaffoldLayout pageTitle="Login">
                <Grid container mt={7}>
                    <Grid item xs={12}>
                        <Image
                            alt=""
                            src={CloudImage}
                            sx={{
                                zIndex: 9999,
                                position: 'absolute',
                                height: '56px',
                                width: '150px'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} px={isSmDown ? 2 : 10}>
                        <Box
                            p={5}
                            sx={{
                                textAlign: 'center',
                                borderRadius: '27.6738px',
                                border: '1.10695px solid #DFDFDF',
                                opacity: 0.9,
                                background: 'linear-gradient(128.68deg, #2F4A7F 24.59%, #00B9F2 137.76%)'
                            }}
                        >
                            <Box component={Typography} color="common.white" variant="h6">
                                {'Login'}
                            </Box>

                            {!loginForm.isSubmitting && loginForm.invalidAttempts && (
                                <Box mt={2} component={Alert} variant="filled" severity="warning">
                                    {'Incorrect email address and/or password'}
                                </Box>
                            )}

                            {!loginForm.isSubmitting && loginForm.error && (
                                <Box mt={2} component={Alert} variant="filled" severity="error">
                                    {'There was an error communicating with the remote server, please try again later.'}
                                </Box>
                            )}

                            <form onSubmit={handleSubmit(handleLogin)}>
                                <Stack mt={3} spacing={3}>
                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <OutlinedInput
                                            id="email"
                                            type="text"
                                            size="small"
                                            autoComplete="off"
                                            disabled={isSubmitting || loginForm.isSubmitting}
                                            placeholder="Enter email address"
                                            fullWidth
                                            sx={{ bgcolor: 'common.white' }}
                                            {...register('email')}
                                        />

                                        {errors.email && (
                                            <FormHelperText error id="login-form-helper-text-email">
                                                {errors.email?.message}
                                            </FormHelperText>
                                        )}
                                    </Stack>

                                    <Stack spacing={1} direction="row" alignItems="center">
                                        <OutlinedInput
                                            fullWidth
                                            id="password"
                                            size="small"
                                            autoComplete="off"
                                            type={showPassword ? 'text' : 'password'}
                                            disabled={isSubmitting || loginForm.isSubmitting}
                                            sx={{ bgcolor: 'common.white' }}
                                            {...register('password')}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showPassword ? (
                                                            <EyeOutlined style={{ fontSize: '20px' }} />
                                                        ) : (
                                                            <EyeInvisibleOutlined style={{ fontSize: '20px' }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            placeholder="Enter password"
                                        />

                                        {errors.password && (
                                            <FormHelperText error id="login-form-helper-text-password">
                                                {errors.password?.message}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                </Stack>

                                <Box mt={5}>
                                    <LoadingButton
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting || loginForm.isSubmitting}
                                        loading={isSubmitting || loginForm.isSubmitting}
                                        sx={{
                                            px: isSmDown ? 10 : 20,
                                            ':hover': {
                                                bgcolor: theme.palette.primary.main
                                            }
                                        }}
                                    >
                                        {'Login'}
                                    </LoadingButton>
                                </Box>
                            </form>

                            <Box mt={3}>
                                <MuiLink
                                    color="common.white"
                                    component={ReactRouterLink}
                                    to={routes.authentication.forgotPassword}
                                    sx={{ fontSize: '15px' }}
                                >
                                    {'Forgot password?'}
                                </MuiLink>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'none',
                                    md: 'none',
                                    lg: 'flex'
                                },
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Image
                                alt=""
                                src={ShippingGroupImage}
                                sx={{
                                    height: '380px',
                                    width: '530px'
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </ScaffoldLayout>
        );
    }

    return null;
};

// ----------------------------------------------------------------------

export default LoginPage;
