// react
import { useEffect } from 'react';

// prop types
import PropTypes from 'prop-types';

// redux
import { useAppSelector, useAppDispatch } from '../../hooks/useRedux';
// actions
import { fetchUser } from '../../store/session/sessionSlice';

// ----------------------------------------------------------------------

const Authentication = ({ children }: any) => {
    // hooks
    // -- redux
    const dispatch = useAppDispatch();
    const session = useAppSelector((state) => state.session);

    useEffect(() => {
        if (!session.checked && !session.verifying) {
            dispatch(fetchUser());
        }
    });

    return <>{children}</>;
};

Authentication.propTypes = {
    children: PropTypes.object.isRequired
};

// ----------------------------------------------------------------------

export default Authentication;
