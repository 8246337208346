const routes = {
    authentication: {
        login: '/',
        forgotPassword: '/forgot-password',
        resetPassword: '/password-recovery/:token'
    },
    dashboard: '/dashboard',
    paymentVouchers: {
        actions: '/payment-vouchers/actions',
        search: '/payment-vouchers/search',
        create: '/payment-vouchers/create',
        edit: '/payment-vouchers/:slug'
    },
    receiptVouchers: {
        actions: '/receipt-vouchers/actions',
        search: '/receipt-vouchers/search',
        create: '/receipt-vouchers/create',
        edit: '/receipt-vouchers/:slug'
    }
};

// ----------------------------------------------------------------------

export default routes;
