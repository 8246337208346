// @mui
// -- styles
import { styled } from '@mui/material/styles';
// // -- components
import MuiRadio, { RadioProps } from '@mui/material/Radio';

// images
// -- local
import LogoImage from '../../assets/images/logo.svg';

// ----------------------------------------------------------------------

export const DefaultIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: '46px',
    height: '46px',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    '.Mui-focusVisible &': {
        outline: `2px auto ${theme.palette.primary.main}`,
        outlineOffset: -1
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)'
    }
}));

export const CheckedIcon = styled(DefaultIcon)(({ theme }) => ({
    backgroundImage: `url(${LogoImage})`,
    backgroundSize: '30px 30px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    outline: `2px auto ${theme.palette.primary.main}`,
    outlineOffset: -1,
    '&:before': {
        width: '46px',
        height: '46px'
    },
    'input:hover ~ &': {
        backgroundColor: '#F5F5F5'
    }
}));

// ----------------------------------------------------------------------

const Radio = (props: RadioProps) => {
    return <MuiRadio disableRipple color="default" checkedIcon={<CheckedIcon />} icon={<DefaultIcon />} {...props} />;
};

// ----------------------------------------------------------------------

export default Radio;
