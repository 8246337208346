// router
import { createBrowserRouter, RouterProvider as ReactRouterDomRouterProvider } from 'react-router-dom';

// routes
// -- constants
import routes from '../../constants/routes';

// ----------------------------------------------------------------------

// elements
// -- authentication - login
import LoginPage from '../../pages/authentication/login';
// -- authentication - forgot password
import ForgotPasswordPage from '../../pages/authentication/forgotPassword';
// -- authentication - reset password
import ResetPasswordPage from '../../pages/authentication/resetPassword';
// -- dashboard page
import DashboardPage from '../../pages/dashboard';
// -- payment vouchers - actions
import PaymentVouchersPage from '../../pages/paymentVouchers/actions';
// -- payment vouchers - search
import PaymentVouchersSearchPage from '../../pages/paymentVouchers/search';
// -- payment vouchers - create
import PaymentVouchersCreatePage from '../../pages/paymentVouchers/create';
// -- payment vouchers - edit
import PaymentVouchersEditPage from '../../pages/paymentVouchers/edit';
// -- receipt vouchers - actions
import ReceiptVouchersPage from '../../pages/receiptVouchers/actions';
// -- receipt vouchers - search
import ReceiptVouchersSearchPage from '../../pages/receiptVouchers/search';
// -- receipt vouchers - create
import ReceiptVouchersCreatePage from '../../pages/receiptVouchers/create';
// -- receipt vouchers - edit
import ReceiptVouchersEditPage from '../../pages/receiptVouchers/edit';

// ----------------------------------------------------------------------

const router = createBrowserRouter([
    {
        path: routes.authentication.login,
        element: <LoginPage />
    },
    {
        path: routes.authentication.forgotPassword,
        element: <ForgotPasswordPage />
    },
    {
        path: routes.authentication.resetPassword,
        element: <ResetPasswordPage />
    },
    {
        path: routes.dashboard,
        element: <DashboardPage />
    },
    {
        path: routes.paymentVouchers.actions,
        element: <PaymentVouchersPage />
    },
    {
        path: routes.paymentVouchers.search,
        element: <PaymentVouchersSearchPage />
    },
    {
        path: routes.paymentVouchers.create,
        element: <PaymentVouchersCreatePage />
    },
    {
        path: routes.paymentVouchers.edit,
        element: <PaymentVouchersEditPage />
    },
    {
        path: routes.receiptVouchers.actions,
        element: <ReceiptVouchersPage />
    },
    {
        path: routes.receiptVouchers.search,
        element: <ReceiptVouchersSearchPage />
    },
    {
        path: routes.receiptVouchers.create,
        element: <ReceiptVouchersCreatePage />
    },
    {
        path: routes.receiptVouchers.edit,
        element: <ReceiptVouchersEditPage />
    }
]);

// ----------------------------------------------------------------------

const RouterProvider = () => <ReactRouterDomRouterProvider router={router} />;

// ----------------------------------------------------------------------

export default RouterProvider;
