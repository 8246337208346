// @mui
// -- system
import { Theme } from '@mui/system/createTheme';
import { Spacing } from '@mui/system/createTheme/createSpacing';

// ----------------------------------------------------------------------

interface TabsProps extends Theme {
    spacing: Spacing;
}

// ----------------------------------------------------------------------

const Tabs = (theme: TabsProps) => {
    return {
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: theme.spacing(1)
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: theme.palette.common.white
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default Tabs;
