// react
import { ReactElement, useState, useEffect } from 'react';

// axios
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

// ----------------------------------------------------------------------

interface AxiosInterceptorProps {
    children: ReactElement<any, any>;
}

// ----------------------------------------------------------------------

const basePath: string = process.env.NODE_ENV === 'production' ? 'https://api.mahalaty.store/api/user' : 'http://localhost:8000/api/user';

const axiosInstance: AxiosInstance = axios.create({
    baseURL: basePath,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true
} as AxiosRequestConfig);

// ----------------------------------------------------------------------

const AxiosInterceptor = ({ children }: AxiosInterceptorProps): JSX.Element | null => {
    // state
    const [isSet, setIsSet] = useState<boolean>(false);

    useEffect(() => {
        const resInterceptor = (response: AxiosResponse) => response;

        const errInterceptor = (error: AxiosError) => Promise.reject(error);

        const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);

        setIsSet(true);

        return () => axiosInstance.interceptors.response.eject(interceptor);
    }, []);

    if (isSet) return children;

    return null;
};

// ----------------------------------------------------------------------

export default axiosInstance;
export { basePath, AxiosInterceptor };
