const Tooltip = () => {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default Tooltip;
