// lodash
import { merge } from 'lodash';

// @mui
// -- system
import { Theme } from '@mui/system/createTheme';
import { Spacing } from '@mui/system/createTheme/createSpacing';

// components
import Avatar from './Avatar';
import Button from './Button';
import Divider from './Divider';
import Drawer from './Drawer';
import IconButton from './IconButton';
import ListItemButton from './ListItemButton';
import ListItemIcon from './ListItemIcon';
import ListItemText from './ListItemText';
import Tab from './Tab';
import Tabs from './Tabs';
import Tooltip from './Tooltip';

// ----------------------------------------------------------------------

interface ComponentsProps extends Theme {
    spacing: Spacing;
}

// ----------------------------------------------------------------------

const Components = (theme: ComponentsProps) => {
    return merge(
        Avatar(),
        Button(),
        Divider(),
        Drawer(),
        IconButton(theme),
        ListItemButton(),
        ListItemIcon(theme),
        ListItemText(theme),
        Tab(theme),
        Tabs(theme),
        Tooltip()
    );
};

// ----------------------------------------------------------------------

export default Components;
