// react
import { useState, useEffect } from 'react';

// router
import { Link as ReactRouterLink, useParams, useSearchParams, useNavigate } from 'react-router-dom';

// @mui
// -- styles
import { useTheme } from '@mui/material/styles';
// -- lab
import { LoadingButton } from '@mui/lab';
// -- components
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import MuiLink from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
// -- icons
import { ArrowLeftOutlined, StopOutlined } from '@ant-design/icons';

// forms
import { useForm, SubmitHandler } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

// routes
// -- constants
import routes from '../../../constants/routes';

// hooks
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';

// redux
// -- actions
import { validatePasswordResetToken, resetPassword, IResetPasswordTokenFormFields } from '../../../store/session/sessionSlice';

// layouts
import ScaffoldLayout from '../../../layouts/scaffold';

// ----------------------------------------------------------------------

interface IValidateResetTokenState {
    isInvalid: boolean;
    email: string;
    token: string;
    isValidating: boolean;
}

interface IResetPasswordFormState {
    tokenExpired: boolean;
    isSubmitting: boolean;
}

// ----------------------------------------------------------------------

const schema = Joi.object({
    token: Joi.string().required(),
    email: Joi.string().required(),
    password: Joi.string().required(),
    password_confirmation: Joi.any()
        .equal(Joi.ref('password'))
        .required()
        .label('Password confirmation')
        .messages({ 'any.only': '{{#label}} does not match' })
});

// ----------------------------------------------------------------------

const ResetPasswordPage = () => {
    // state
    // -- validate state
    const [validate, setValidate] = useState<IValidateResetTokenState>({
        isInvalid: false,
        email: '',
        token: '',
        isValidating: true
    });
    // -- reset password state
    const [resetPasswordForm, setResetPasswordForm] = useState<IResetPasswordFormState>({
        tokenExpired: false,
        isSubmitting: false
    });

    // hooks
    // -- theme
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    // -- router
    const navigate = useNavigate();
    // -- router - url segments
    const { token } = useParams();
    // -- router - query params
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    // -- redux
    const dispatch = useAppDispatch();
    const session = useAppSelector((state) => state.session);
    // -- forms
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<IResetPasswordTokenFormFields>({
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: joiResolver(schema),
        defaultValues: {
            token: validate.token,
            email: validate.email
        }
    });

    // form callback(s)
    // -- validate reset token
    const validateToken = async () => {
        dispatch(validatePasswordResetToken({ token: token ?? '', email: email ?? '' }))
            .unwrap()
            .then((res) => {
                setValidate({
                    ...validate,
                    token: res.data.token,
                    email: res.data.email,
                    isValidating: false
                });
            })
            .catch(() => {
                setValidate({
                    ...validate,
                    isInvalid: true,
                    isValidating: false
                });
            });
    };
    // reset
    const handleReset: SubmitHandler<IResetPasswordTokenFormFields> = async (data: IResetPasswordTokenFormFields) => {
        setResetPasswordForm({
            ...resetPasswordForm,
            isSubmitting: true
        });

        dispatch(resetPassword(data))
            .unwrap()
            .then((res) => {
                navigate(routes.authentication.login);
            })
            .catch((err) => {
                if (err.code === 'ERR_BAD_REQUEST') {
                    setResetPasswordForm({
                        ...resetPasswordForm,
                        isSubmitting: false
                    });

                    setValidate({
                        ...validate,
                        isInvalid: true
                    });
                }
            });
    };

    useEffect(() => {
        if (session.checked && !session.verified) {
            validateToken();
        }

        if (session.checked && session.verified) {
            navigate(routes.dashboard);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    if (session.checked && !session.verified) {
        return (
            <ScaffoldLayout showSideImages pageTitle="Password recovery">
                <Grid container mt={7}>
                    <Grid item xs={12} px={isSmDown ? 2 : 10} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {validate.isInvalid || resetPasswordForm.tokenExpired ? (
                            <Stack
                                p={5}
                                spacing={3}
                                sx={{
                                    maxWidth: '640px',
                                    textAlign: 'center'
                                }}
                            >
                                <StopOutlined style={{ color: '#f76b6e', fontSize: '60px' }} />

                                <Typography>
                                    {
                                        'The password reset link was invalid, possibly because it has already been used or expired. Please request a new password reset link.'
                                    }
                                </Typography>
                            </Stack>
                        ) : (
                            <Box
                                p={5}
                                sx={{
                                    maxWidth: '640px',
                                    textAlign: 'center',
                                    borderRadius: '27.6738px',
                                    border: '1.10695px solid #DFDFDF',
                                    opacity: 0.9,
                                    background: 'linear-gradient(128.68deg, #2F4A7F 24.59%, #00B9F2 137.76%)'
                                }}
                            >
                                <Box component={Typography} color="common.white" variant="h6">
                                    {'Reset your password?'}
                                </Box>

                                <form onSubmit={handleSubmit(handleReset)}>
                                    <Stack mt={3} spacing={3}>
                                        <Stack spacing={1} direction="row" alignItems="center">
                                            <OutlinedInput
                                                fullWidth
                                                id="password"
                                                size="small"
                                                autoComplete="off"
                                                type={'password'}
                                                disabled={isSubmitting || resetPasswordForm.isSubmitting}
                                                sx={{ bgcolor: 'common.white' }}
                                                {...register('password')}
                                                placeholder="Enter password"
                                            />

                                            {errors.password && (
                                                <FormHelperText error id="reset-password-form-helper-text-password">
                                                    {errors.password?.message}
                                                </FormHelperText>
                                            )}
                                        </Stack>

                                        <Stack spacing={1} direction="row" alignItems="center">
                                            <OutlinedInput
                                                fullWidth
                                                id="password_confirmation"
                                                size="small"
                                                autoComplete="off"
                                                type={'password'}
                                                disabled={isSubmitting || resetPasswordForm.isSubmitting}
                                                sx={{ bgcolor: 'common.white' }}
                                                {...register('password_confirmation')}
                                                placeholder="Enter password confirmation"
                                            />

                                            {errors.password_confirmation && (
                                                <FormHelperText error id="reset-password-form-helper-text-password-confirmation">
                                                    {errors.password_confirmation?.message}
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Stack>

                                    <Box mt={3}>
                                        <LoadingButton
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => console.log(errors)}
                                            disabled={isSubmitting || resetPasswordForm.isSubmitting}
                                            loading={isSubmitting || resetPasswordForm.isSubmitting}
                                            sx={{
                                                px: isSmDown ? 10 : 20,
                                                ':hover': {
                                                    bgcolor: theme.palette.primary.main
                                                }
                                            }}
                                        >
                                            {'Request reset link'}
                                        </LoadingButton>
                                    </Box>
                                </form>

                                <Box mt={4}>
                                    <MuiLink
                                        color="common.white"
                                        component={ReactRouterLink}
                                        to={routes.authentication.login}
                                        sx={{ fontSize: '15px' }}
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <ArrowLeftOutlined />

                                            <Typography variant="subtitle2">{'Back to login'}</Typography>
                                        </Stack>
                                    </MuiLink>
                                </Box>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </ScaffoldLayout>
        );
    }

    return null;
};

// ----------------------------------------------------------------------

export default ResetPasswordPage;
