const MuiListItemButton = () => {
    return {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#4fc3f7'
                    }
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default MuiListItemButton;
