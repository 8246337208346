// @mui
// -- system
import { Theme } from '@mui/system/createTheme';
import { Spacing } from '@mui/system/createTheme/createSpacing';

// ----------------------------------------------------------------------

interface ListItemTextProps extends Theme {
    spacing: Spacing;
}

// ----------------------------------------------------------------------

const ListItemText = (theme: ListItemTextProps) => {
    return {
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default ListItemText;
