// @mui
// -- system
import { Theme } from '@mui/system/createTheme';
import { Spacing } from '@mui/system/createTheme/createSpacing';

// ----------------------------------------------------------------------

interface ListItemIconProps extends Theme {
    spacing: Spacing;
}

// ----------------------------------------------------------------------

const ListItemIcon = (theme: ListItemIconProps) => {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(2),
                    '& svg': {
                        fontSize: 20
                    }
                }
            }
        }
    };
};

// ----------------------------------------------------------------------

export default ListItemIcon;
