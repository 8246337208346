// react
import { StrictMode } from 'react';

// react dom
import { createRoot } from 'react-dom/client';

// providers, theme, containers and contexts
// -- redux
import { Provider as ReduxProvider } from 'react-redux';
// -- x date pickers
import { LocalizationProvider as XDatePickersLocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// -- theme
import ThemeProvider from './theme';
// -- settings context
import { SettingsProvider } from './contexts/settings';
// -- notistack
import { SnackbarProvider } from 'notistack';
// -- axios container
import { AxiosInterceptor as AxiosContainer } from './containers/axios';
// -- router container
import RouterContainer from './containers/router';
// -- authentication container
import AuthenticationContainer from './containers/authentication';
// -- redux store
import { store } from './store';

// import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

// -- alexandria
import './assets/styles/cairo.css';

// lazy image loader
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// ----------------------------------------------------------------------

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <StrictMode>
        <ReduxProvider store={store}>
            <AxiosContainer>
                <AuthenticationContainer>
                    <XDatePickersLocalizationProvider dateAdapter={AdapterMoment}>
                        <SettingsProvider>
                            <ThemeProvider>
                                <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
                                    <RouterContainer />
                                </SnackbarProvider>
                            </ThemeProvider>
                        </SettingsProvider>
                    </XDatePickersLocalizationProvider>
                </AuthenticationContainer>
            </AxiosContainer>
        </ReduxProvider>
    </StrictMode>
);

// ----------------------------------------------------------------------

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
