// redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// axios
import axios from '../../containers/axios';

// ----------------------------------------------------------------------

export interface ILoginFormFields {
    email: string;
    password: string;
}

export interface IForgotPasswordFormFields {
    email: string;
}

export interface IResetPasswordTokenFormFields {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
}

// ----------------------------------------------------------------------

interface ILoginFormPayload extends ILoginFormFields {
    //
}

interface IForgotPasswordFormPayload extends IForgotPasswordFormFields {
    //
}

interface IValidateResetPasswordTokenPayload {
    token: string;
    email: string;
}

interface IResetPasswordTokenFormPayload extends IResetPasswordTokenFormFields {
    //
}

// ----------------------------------------------------------------------

export interface UserModel {
    id: string;
    name: string;
    email: string;
}

interface UserState {
    checked: boolean;
    verifying: boolean;
    verified: boolean;
    data: UserModel;
}

// ----------------------------------------------------------------------

export const initialState: UserState = {
    checked: false,
    verifying: false,
    verified: false,
    data: {
        id: '',
        name: '',
        email: ''
    }
};

// ----------------------------------------------------------------------

export const fetchUser = createAsyncThunk('session/fetchUser', () => {
    return axios.get('users/auth/user').then((res) => res);
});

export const authenticate = createAsyncThunk('session/authenticate', (data: ILoginFormPayload) => {
    return axios.post('users/auth/login', data).then((res) => res);
});

export const requestPasswordRecovery = createAsyncThunk('session/requestPasswordRecovery', (data: IForgotPasswordFormPayload) => {
    return axios.put('users/auth/password/email', data).then((res) => res);
});

export const validatePasswordResetToken = createAsyncThunk(
    'session/validatePasswordResetToken',
    (data: IValidateResetPasswordTokenPayload) => {
        return axios
            .get('users/auth/password/find/:token?email=:email'.replace(':token', data.token).replace(':email', data.email))
            .then((res) => res);
    }
);

export const resetPassword = createAsyncThunk('session/resetPassword', (data: IResetPasswordTokenFormPayload) => {
    return axios.patch('users/auth/password/reset', data).then((res) => res);
});

export const logout = createAsyncThunk('session/logout', () => axios.post('users/auth/logout').then((res) => res));

// ----------------------------------------------------------------------

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // authenticate
        builder.addCase(authenticate.pending, (state, action) => {
            state.verifying = true;
        });
        builder.addCase(authenticate.fulfilled, (state, action) => {
            state.checked = true;
            state.verifying = false;
            state.verified = true;

            state.data.id = action.payload.data.data.id;
            state.data.name = action.payload.data.data.name;
            state.data.email = action.payload.data.data.email;
        });
        builder.addCase(authenticate.rejected, (state, action) => {
            state.checked = true;
            state.verifying = false;
            state.verified = false;
        });

        // fetch record
        builder.addCase(fetchUser.pending, (state, action) => {
            state.verifying = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.checked = true;
            state.verifying = false;
            state.verified = true;

            state.data.id = action.payload.data.data.id;
            state.data.name = action.payload.data.data.name;
            state.data.email = action.payload.data.data.email;
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.checked = true;
            state.verifying = false;
            state.verified = false;
        });

        // logout
        builder.addCase(logout.fulfilled, (state, action) => {
            state.checked = true;
            state.verifying = false;
            state.verified = false;

            state.data = initialState.data;
        });
    }
});

// ----------------------------------------------------------------------

export default sessionSlice.reducer;
